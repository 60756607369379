import React, { Component } from 'react';
import { Modal, Text, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { isEmpty, isNil } from 'lodash';
import { object, func, bool, any, string, array } from 'prop-types';
import { showMessage } from 'react-native-flash-message';

import s from './styles';
import { Color } from '../../theme';
import FieldInput from '../FieldInput/FieldInput';
import DropdownList from '../DropdownList/DropdownList';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import LinkButton from '../LinkButton/LinkButton';

class NavigationBar extends Component {
    state = {
        isDropdownOpen: false,
        isSearchOpen: false,
        searchField: '',
        isConfirmationOpen: false,
    };

    goBack = () => {
        const { backConfirm, navigation, backIconFunction } = this.props;
        const { goBack } = navigation || {};
        if (isEmpty(backConfirm)) {
            if (typeof backIconFunction === typeof Function) {
                backIconFunction();
            } else {
                goBack(null);
            }
        } else {
            this.setState({ isConfirmationOpen: true });
        }
    };

    render() {
        const {
            user,
            currentTimeCard,
            backIcon,
            disableBackIcon,
            backIconFunction,
            menuIcon,
            navigation,
            title,
            dropdownMenu,
            filterComponent,
            searchIcon,
            onSearch,
            backConfirm,
            rightComponent,
            userLogin,
        } = this.props;
        const { isDropdownOpen, isSearchOpen, searchField, isConfirmationOpen } = this.state;
        const { goBack } = navigation || {};

        let isClockIn = false;
        if (currentTimeCard) {
            isClockIn = currentTimeCard.isClockIn;
        }

        const companies = user && user.companies;

        const leftItems = [];
        if (backIcon) {
            leftItems.push(
                <TouchableOpacity key="back" onPress={this.goBack} disabled={disableBackIcon}>
                    <View style={[s.iconWrapper, s.backIcon]}>
                        <Icon
                            name="chevron-left"
                            size={30}
                            style={[s.arrowBack, disableBackIcon && s.arrowBack_disabled]}
                        />
                    </View>
                </TouchableOpacity>,
            );
        }

        if (menuIcon) {
            leftItems.push(
                <TouchableOpacity key="menu" onPress={() => navigation.openDrawer()}>
                    <View style={[s.iconWrapper, s.backIcon]}>
                        <Icon name="menu" style={[s.menuIcon]} />
                    </View>
                </TouchableOpacity>,
            );
        }

        if (companies && companies.length > 1 && !backIcon) {
            const selectedCompany = companies.find((company) => company.isCurrent);
            leftItems.push(
                <DropdownList
                    key="companies"
                    containerStyle={s.companiesDropdown}
                    selectedItem={{ id: selectedCompany.settingId, title: selectedCompany.company }}
                    title="COMPANY"
                    items={companies.map((company) => ({
                        id: company.settingId,
                        title: company.company,
                        username: company.adminUsername,
                    }))}
                    withIds
                    onItemSelect={(item) => {
                        if (userLogin) {
                            if (isClockIn) {
                                showMessage({
                                    message: 'You are already clock in',
                                    description: 'Please clock out before switching companies',
                                    type: 'danger',
                                    duration: 5000,
                                    animationDuration: 700,
                                    hideOnPress: true,
                                    hideStatusBar: true,
                                    backgroundColor: Color.red,
                                });
                                return;
                            }

                            userLogin({ email: item.username });
                        }
                    }}
                />,
            );
        }

        const isLeftEmpty = !backIcon && !menuIcon && !companies;
        const isRightEmpty = !rightComponent;

        return (
            <View>
                <View style={[s.topRow]}>
                    <View style={s.leftComponentWrapper}>{leftItems}</View>
                    <View style={[s.titleComponentWrapper, isLeftEmpty && isRightEmpty && s.titleComponentFullWidth]}>
                        {!isNil(title) && (
                            <Text numberOfLines={2} style={[s.title]}>
                                {title}
                            </Text>
                        )}
                    </View>
                    <View style={s.rightComponentWrapper}>
                        {!!rightComponent && rightComponent}
                        {!!filterComponent && filterComponent}
                        {!isEmpty(dropdownMenu) && (
                            <View>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ isDropdownOpen: true });
                                    }}
                                >
                                    <Icon name="dots-vertical" size={25} color={Color.turquoise} />
                                </TouchableOpacity>
                                <Modal visible={isDropdownOpen} transparent>
                                    <TouchableOpacity
                                        style={s.modalBackGround}
                                        onPress={() => this.setState({ isDropdownOpen: false })}
                                    />
                                    <View style={s.dropdownWrapper}>
                                        {dropdownMenu.map((item, i) => {
                                            return (
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        item.onPress();
                                                        this.setState({ isDropdownOpen: false });
                                                    }}
                                                    style={s.dropDownItem}
                                                    key={i}
                                                >
                                                    <Text style={s.dropDownItemText}>{item.title}</Text>
                                                </TouchableOpacity>
                                            );
                                        })}
                                    </View>
                                </Modal>
                            </View>
                        )}
                        {searchIcon && (
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({ isSearchOpen: true });
                                }}
                            >
                                <Icon name="magnify" size={25} color={Color.turquoise} />
                            </TouchableOpacity>
                        )}
                    </View>
                    {isSearchOpen && (
                        <View style={isSearchOpen && s.searchOpen}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({ isSearchOpen: true });
                                }}
                            >
                                <Icon name="magnify" size={25} color={Color.turquoise} />
                            </TouchableOpacity>
                            {isSearchOpen && (
                                <View style={{ flex: 1 }}>
                                    <FieldInput
                                        placeholder="Search"
                                        style={s.searchFieldContainer}
                                        inputStyle={s.searchFieldInput}
                                        input={{
                                            onChange: (t) => {
                                                if (t.length > 2) {
                                                    onSearch(t);
                                                }
                                                this.setState({ searchField: t });
                                            },
                                        }}
                                        onEndEditing={(t) => {
                                            this.setState({ searchField: t });
                                            onSearch(t);
                                        }}
                                        meta={{
                                            error: null,
                                            touched: false,
                                        }}
                                        initialValues={searchField}
                                    />
                                </View>
                            )}
                            {isSearchOpen && (
                                <TouchableOpacity
                                    onPress={() => {
                                        onSearch('');
                                        this.setState({
                                            isSearchOpen: false,
                                            searchField: '',
                                        });
                                    }}
                                >
                                    <Icon name="close" size={25} color={Color.turquoise} />
                                </TouchableOpacity>
                            )}
                        </View>
                    )}
                </View>
                <View>
                    {!isEmpty(backConfirm) && (
                        <Modal visible={isConfirmationOpen} transparent>
                            <TouchableOpacity
                                style={[s.modalBackGround, s.confirmationModal]}
                                onPress={() => this.setState({ isConfirmationOpen: false })}
                            >
                                <View style={s.confirmationForm}>
                                    <Text style={s.confirmationTitle}>{backConfirm.title}</Text>
                                    <Text style={s.confirmationText}>{backConfirm.text}</Text>
                                    <View style={s.confirmationButtons}>
                                        <View style={s.confirmationButton}>
                                            <PrimaryButton
                                                onPress={() => {
                                                    this.setState({ isConfirmationOpen: false });
                                                    if (typeof backIconFunction === typeof Function) {
                                                        backIconFunction();
                                                    } else {
                                                        goBack(null);
                                                    }
                                                }}
                                                title="OK"
                                            />
                                        </View>
                                        <View style={s.confirmationButton}>
                                            <LinkButton
                                                onPress={() => this.setState({ isConfirmationOpen: false })}
                                                title="CANCEL"
                                            />
                                        </View>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        </Modal>
                    )}
                </View>
            </View>
        );
    }
}

NavigationBar.propTypes = {
    user: object,
    currentTimeCard: object,
    backIcon: bool,
    menuIcon: bool,
    disableBackIcon: bool,
    navigation: object,
    title: string,
    rightComponent: any,
    dropdownMenu: array,
    filterComponent: any,
    searchIcon: bool,
    onSearch: func,
    backConfirm: object,
    backIconFunction: any,
    userLogin: func,
};

export default NavigationBar;
