import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, ImageBackground, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { cloneDeep, isEmpty, isEqual, isNil } from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { NavigationActions, NavigationEvents } from 'react-navigation';
import { showMessage } from 'react-native-flash-message';
// import Dash from 'react-native-dash';
import { ConfirmationDialog, NavigationBar, PrimaryButton, TopGradient } from '../../../components/index';
import DailyQuestionDialog from './DailyQuestionDialog';
import {
    adminClockOut,
    breakIn,
    breakOut,
    changeBreakTime,
    changeClockInTime,
    changeCostCode,
    setTimecardChange,
    changeProject,
    cleanUpTimeCard,
    clockIn,
    clockOut,
    setDescription,
    setWorkOrderNumber,
    setWorkOrderYear,
    setSwitch,
    setLoading,
    setBreakOutLoading,
} from '../../../store/modules/currentTimeCard';
import OutstandingItemDialog from '../../../components/OutstandingItemDialog/OutstandingItemDialog';
import {
    cacheProfilePhoto,
    getCostCode,
    getDailyQuestions,
    getProject,
    getProjectByExternalId,
    profilePhotoUploading,
    profilePhotoUploadingFinish,
    saveProfilePhoto,
    userUpdate,
    userLogin,
    userLogOut,
    getGeneralRulesSettings,
    getTimeCardRules,
} from '../../../store/modules/account';
import {
    cleanUp,
    setCurrentCostCode,
    setCurrentProject,
    setUserViewingReports,
} from '../../../store/modules/dailyReports';

import { getTimeCards, syncTimeCard, submitDailyQuestion } from '../../../store/modules/timeCards';
import { selectTimeCardEdit } from '../../../store/modules/timeCardEdit';

import { uploadImage } from '../../../store/helpers/common';
import { formatTimeFromSeconds, checkIpLock } from '../../../utils/helpers';
import hasAccessTo from '../../../utils/auth';
import { Config } from '../../../config';

import s from './styles';
import { Color, Img } from '../../../theme';

const CONFIRMATION_TYPES = {
    SWITCH: 'S',
    GO_TO_OUTSTANDING_ITEMS: 'A',
    CREATE_DAILY_REPORT: 'C',
    CLOCK_OUT: 'B', // TODO: is it correct type name?
    PERMISSIONS: 'P',
};

class ClockScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentTimeCard: PropTypes.object,
        lastTimeCard: PropTypes.object,
        user: PropTypes.object,
        cachedPhoto: PropTypes.object,
        currentReport: PropTypes.object,
        unsavedCards: PropTypes.array,
        timeCardsIsSyncing: PropTypes.bool,
        groupedCards: PropTypes.array,
        dailyReportIsChanged: PropTypes.bool,
        dailyReportIsSaving: PropTypes.bool,
        isPhotoUploading: PropTypes.bool,
        isDataLoading: PropTypes.bool,
        isAutoLoginLoading: PropTypes.bool,
        isBreakInLoading: PropTypes.bool,
        changeClockInTime: PropTypes.func,
        setCurrentProject: PropTypes.func,
        setCurrentCostCode: PropTypes.func,
        cleanUp: PropTypes.func,
        adminClockOut: PropTypes.func,
        breakIn: PropTypes.func,
        clockOut: PropTypes.func,
        setSwitch: PropTypes.func,
        setDescription: PropTypes.func,
        setWorkOrderNumber: PropTypes.func,
        setWorkOrderYear: PropTypes.func,
        getDailyQuestions: PropTypes.func,
        submitDailyQuestion: PropTypes.func,
        uploadImage: PropTypes.func,
        saveProfilePhoto: PropTypes.func,
        profilePhotoUploading: PropTypes.func,
        profilePhotoUploadingFinish: PropTypes.func,
        cacheProfilePhoto: PropTypes.func,
        setTimecardChange: PropTypes.func,
        changeProject: PropTypes.func,
        getCostCode: PropTypes.func,
        changeCostCode: PropTypes.func,
        changeBreakTime: PropTypes.func,
        syncTimeCard: PropTypes.func,
        getTimeCards: PropTypes.func,
        setLoading: PropTypes.func,
        setBreakOutLoading: PropTypes.func,
        userUpdate: PropTypes.func,
        setUserViewingReports: PropTypes.func,
        isDailyQuestionActive: PropTypes.bool,
        latestDailyQuestions: PropTypes.array,
        latestDailyQuestionsTitle: PropTypes.string,
        firstPayPeriod: PropTypes.object,
        userLogOut: PropTypes.func,
        settings: PropTypes.object,
        getGeneralRulesSettings: PropTypes.func,
        generalRules: PropTypes.object,
        costCodes: PropTypes.array,
        projects: PropTypes.array,
        clockIn: PropTypes.func,
        getTimeCardRules: PropTypes.func,
        selectTimeCardEdit: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            timer: 0,
            total: 0,
            showConfirmationDialog: false,
            showModifyConfirmationDialog: false,
            showRemindersConfirmationDialog: false,
            showNotSignedTimecardConfirmationDialog: false,
            showPermissionsDialog: false,
            // confirmationDialogThree: false,
            confirmationTitle: '',
            confirmationText: '',
            isSwitch: false,
            isLoading: false,
            showLocationMessage: false,
            enableChangeProject: false,
        };
    }

    componentDidMount() {
        const { navigation, currentTimeCard, getGeneralRulesSettings, getTimeCardRules } = this.props;
        const { isClockIn, breakIn, breakOut } = currentTimeCard;
        getGeneralRulesSettings();
        getTimeCardRules();
        if (isClockIn) {
            this.setTimer();
        } else if (breakIn.length > 0 && breakIn.length !== breakOut.length) {
            navigation.navigate('Break');
        }
    }

    componentDidUpdate(prevProps) {
        const {
            currentTimeCard,
            groupedCards,
            changeClockInTime,
            setCurrentProject,
            setCurrentCostCode,
            cleanUp,
            navigation,
            lastTimeCard,
            adminClockOut,
            user,
        } = this.props;
        const { currentTimeCard: prevCurrentTimeCard } = prevProps;

        // check if we need go to break
        if (
            !isEqual(currentTimeCard.breakIn, prevCurrentTimeCard.breakIn) ||
            !isEqual(currentTimeCard.breakOut, prevCurrentTimeCard.breakOut)
        ) {
            if (
                currentTimeCard.breakIn.length > 0 &&
                currentTimeCard.breakIn.length !== currentTimeCard.breakOut.length
            ) {
                this.clearTimer();
                navigation.navigate('Break');
            }
        }

        if (lastTimeCard && lastTimeCard.WorkTimeId && currentTimeCard.WorkTimeId === lastTimeCard.WorkTimeId) {
            if (lastTimeCard.EndTime === null && currentTimeCard.currentProject && currentTimeCard.currentCostCode) {
                if (!currentTimeCard.isSwitch) {
                    // Check for project change
                    if (lastTimeCard.Project !== currentTimeCard.currentProject.id) {
                        this.changeProject(lastTimeCard.Project);
                    }
                    // Check for cost code change
                    if (lastTimeCard.CostCode !== currentTimeCard.currentCostCode.id) {
                        this.changeCostCode(lastTimeCard.CostCode);
                    }
                    // Check the start time
                    if (lastTimeCard.StartTime !== currentTimeCard.clockIn[0]) {
                        changeClockInTime(lastTimeCard.StartTime);
                    }
                }
            }

            // Check for end time
            if (lastTimeCard.EndTime) {
                adminClockOut();
                this.clearTimer();
                navigation.navigate('Reports');
                navigation.goBack(null);
                cleanUp();
                navigation.navigate('Clock');
                setCurrentCostCode({ code: null });
                setCurrentProject(null);
                this.setState({
                    timer: 0,
                    total: 0,
                });
            }
        }

        if (
            prevCurrentTimeCard.WorkTimeId !== currentTimeCard.WorkTimeId ||
            prevCurrentTimeCard.isClockIn !== currentTimeCard.isClockIn
        ) {
            if (currentTimeCard.isClockIn) {
                let lastWeeksTimeCard = null;
                const lastWeeksTimeCardDate = moment().subtract(1, 'weeks').startOf('isoWeek');
                for (let i = 0; i < groupedCards.length; i++) {
                    if (lastWeeksTimeCardDate.isSame(groupedCards[i].StartTime, 'day')) {
                        lastWeeksTimeCard = groupedCards[i];
                    }
                }

                this.setTimer();
                if (lastWeeksTimeCard && !lastWeeksTimeCard.Sign.Image && hasAccessTo(user, Config.TIMECARDS)) {
                    this.setState({ showNotSignedTimecardConfirmationDialog: false });
                }
            } else {
                this.clearTimer();
            }
        }

        if (
            prevCurrentTimeCard.clockOut.length !== currentTimeCard.clockOut.length &&
            currentTimeCard.clockOut.length >= 1
        ) {
            this.setState({
                total: this.getTotalTime(),
            });
        }

        if (
            !currentTimeCard.isSwitch &&
            prevCurrentTimeCard.isSwitch &&
            prevCurrentTimeCard.WorkTimeId !== currentTimeCard.WorkTimeId
        ) {
            this.setState({
                total: 0,
            });
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    /**
     * Getting total working time before last break
     **/
    getTotalTime() {
        const { currentTimeCard } = this.props;
        const { clockIn, clockOut } = currentTimeCard;

        let total = 0;
        for (let i = 0; i < clockOut.length; i++) {
            const clockOutTime = clockOut[i];

            if (clockIn[i]) {
                total += moment(clockOutTime).diff(clockIn[i], 'second');
            }
        }

        return total;
    }

    /**
     * Getting elapsed working time since the last break
     **/
    getElapsedTime() {
        const { currentTimeCard } = this.props;
        const { clockIn } = currentTimeCard;

        if (clockIn.length > 0) {
            const lastTimestamp = clockIn[clockIn.length - 1];
            return moment().diff(moment(lastTimestamp), 'second');
        }

        return 0;
    }

    setTimer() {
        this.setState({
            timer: this.getElapsedTime(),
            total: this.getTotalTime(),
        });

        this.clearTimer();
        this.timer = setInterval(this.clockTimer, 1000);
    }

    clockTimer = () => {
        this.setState({
            timer: this.getElapsedTime(),
        });
    };

    clearTimer() {
        if (this.timer) {
            this.setState({
                timer: 0,
                total: 0,
            });
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    getWeek() {
        const { groupedCards } = this.props;
        if (!isEmpty(groupedCards)) {
            return moment(groupedCards[groupedCards.length - 1].StartTime).week();
        }
        return moment().week();
    }

    breakStart = () => {
        const { navigation, breakIn, currentTimeCard } = this.props;
        this.clearTimer();
        this.setState({ timer: 0 });
        breakIn({ currentTimeCard }).then(() => {
            this.postUnsavedTimeCards(true);
            navigation.navigate('Break');
        });
    };

    clockOut = () => {
        const { clockOut, setCurrentProject, setCurrentCostCode, currentTimeCard, cleanUp, navigation } = this.props;

        this.clearTimer();
        this.setState({
            showConfirmationDialog: false,
        });
        clockOut({
            currentTimeCard,
        }).then(() => {
            cleanUp();
            setCurrentCostCode({ code: null });
            setCurrentProject(null);
            this.setState({
                timer: 0,
                total: 0,
                isSwitch: false,
            });
            this.postUnsavedTimeCards(false);
            navigation.navigate('Reports');
            navigation.goBack(null);
            navigation.navigate('Clock');
        });
    };

    onSwitch = () => {
        const {
            navigation,
            setSwitch,
            setCurrentProject,
            setCurrentCostCode,
            setDescription,
            setWorkOrderNumber,
            setWorkOrderYear,
            currentTimeCard,
            user,
            cleanUp,
        } = this.props;

        const data = {
            currentTimeCard: cloneDeep(currentTimeCard),
            user,
        };

        setSwitch(true, data);
        navigation.navigate('ClockIn');
        cleanUp();
        setDescription({ description: '' });
        setWorkOrderNumber({ workOrderNumber: '' });
        setWorkOrderYear({ workOrderYear: '' });
        setCurrentCostCode({ code: null });
        setCurrentProject(null);
        this.setState({
            showConfirmationDialog: false,
            isSwitch: false,
        });
    };

    logout = async () => {
        const { user, userLogOut, navigation } = this.props;
        const { isTrialExpired } = user;
        let message = 'Account Deactivated';
        if (isTrialExpired) {
            message = 'The trial period has been expired';
        }
        navigation.navigate('StartStack');
        await this.postUnsavedTimeCards(true);
        userLogOut();
        showMessage({
            message,
            description: 'Please contact the admin',
            type: 'danger',
            duration: 10000,
            animationDuration: 700,
            hideOnPress: true,
            hideStatusBar: true,
            backgroundColor: Color.red,
        });
    };

    getDefaultProject = async () => {
        const { user, projects } = this.props;
        let project = null;

        if (user.meta.TimeCardRules.IsLockDefaultDepartment && !isNil(user.meta.DefaultDepartment)) {
            project = await getProjectByExternalId(user.meta.DefaultDepartment);
        }

        if (!project && projects && projects.length === 1) {
            project = projects[0];
        }

        return project;
    };

    getDefaultCostCode = () => {
        const { costCodes } = this.props;
        let costCode = null;

        if (costCodes && costCodes.length === 1) {
            costCode = costCodes[0];
        }

        return costCode;
    };

    handleClockInPress = () => {
        this.clockIn();
    };

    clockIn = async (selectedProject = null, selectedCostCode = null) => {
        const {
            navigation,
            getDailyQuestions,
            user,
            userUpdate,
            getTimeCards,
            setUserViewingReports,
            settings,
            userLogOut,
            clockIn,
            changeProject,
            changeCostCode,
            getGeneralRulesSettings,
            getTimeCardRules,
        } = this.props;
        this.setState({ isLoading: true });

        getGeneralRulesSettings();
        getTimeCardRules();

        const { lastTimeCard } = await getTimeCards({ user });

        if (!lastTimeCard || (lastTimeCard && lastTimeCard.EndTime !== null)) {
            const isIpCorrect = await checkIpLock(settings, 'Clock in error', 'Access denied for this IP address');
            if (isIpCorrect) {
                setUserViewingReports(null);
                userUpdate(user._id);
                getDailyQuestions();
                this.clearDescription();

                const project = selectedProject || (await this.getDefaultProject());
                const costCode = selectedCostCode || this.getDefaultCostCode();

                if (project) {
                    changeProject(project);
                }

                if (costCode) {
                    changeCostCode(costCode);
                }

                if ((project && costCode) || (project && !costCode && project.ProjectEstimates.length === 0)) {
                    await clockIn({
                        currentTimeCard: this.props.currentTimeCard,
                    });
                    this.postUnsavedTimeCards(true);
                    this.setState({ isLoading: false, enableChangeProject: false });
                } else {
                    this.setState({ isLoading: false, enableChangeProject: true });
                    navigation.navigate('ClockIn');
                }
            } else {
                await this.postUnsavedTimeCards(true);
                this.setState({ isLoading: false });
                userLogOut();
                navigation.navigate('StartStack');
            }
        } else {
            this.setState({ isLoading: false });
            window.location.reload();
        }
    };

    forgetToClockIn = async () => {
        const { navigation, selectTimeCardEdit } = this.props;
        selectTimeCardEdit(null, null, true);
        navigation.navigate('TimeCardEdit', { isForgotClockIn: true });
    };

    confirmationOnPress = (item) => {
        const { navigation, setDescription } = this.props;
        const { description } = { description: '' };
        switch (item) {
            case CONFIRMATION_TYPES.SWITCH:
                this.onSwitch();
                break;
            case CONFIRMATION_TYPES.GO_TO_OUTSTANDING_ITEMS:
                this.setState({ showNotSignedTimecardConfirmationDialog: false });
                navigation.navigate(
                    'OutstandingReports',
                    {},
                    NavigationActions.navigate({
                        routeName: 'OutstandingTimeCards',
                    }),
                );
                break;
            case CONFIRMATION_TYPES.CLOCK_OUT:
                setDescription({ description });
                this.clockOut();
                break;
            case CONFIRMATION_TYPES.CREATE_DAILY_REPORT:
                // this.setState({ confirmationDialogThree: false })
                navigation.navigate('ReportsStack', {}, NavigationActions.navigate({ routeName: 'Reports' }));
                break;
            default:
                setDescription({ description });
                this.clockOut();
        }
    };

    clearDescription = () => {
        const { setDescription } = this.props;
        const { description } = { description: '' };
        setDescription({ description });
    };

    clockOutCheck = (isSwitch) => {
        const { dailyReportIsChanged, dailyReportIsSaving, currentReport, currentTimeCard, generalRules } = this.props;
        let confirmationTitle = '';
        let confirmationText = '';
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project';
        if ((dailyReportIsChanged || currentReport) && !dailyReportIsSaving) {
            confirmationText =
                // eslint-disable-next-line max-len
                'It looks like you have a daily report in progress. Please go back and save or cancel the report before switching projects';
            this.setState({
                confirmationText,
                showRemindersConfirmationDialog: true,
            });
        } else if (
            currentTimeCard.currentProject &&
            currentTimeCard.currentProject.ProjectType === 'W' &&
            isEmpty(currentTimeCard.workOrderNumber)
        ) {
            confirmationText =
                // eslint-disable-next-line max-len
                `You are clocked into a work order ${projectTitle}. You will need to put the work order in before clocking out.`;
            this.setState({
                confirmationText,
                showRemindersConfirmationDialog: true,
            });
        } else {
            if (isSwitch) {
                confirmationText = `This will clock you out of your current ${projectTitle} so you can clock in to a new one`;
            } else {
                confirmationTitle = `Are you sure you want to clock out of this ${projectTitle}?`;
            }

            confirmationText +=
                'I hereby attest that the time card submitted accurately reflects the hours worked during the specified period. I have reviewed the records and confirm that they align with the work performed during that time frame.';

            if (generalRules && generalRules.showAttestation && generalRules.attestationText) {
                confirmationText += '\n\n';
                confirmationText += generalRules.attestationText;
            }

            this.setState({
                confirmationText,
                confirmationTitle,
                showConfirmationDialog: true,
            });
        }
    };

    submitDailyQuestions = (data) => {
        const { submitDailyQuestion, currentTimeCard, user, latestDailyQuestionsTitle } = this.props;
        const week = this.getWeek();
        const currentPeriod = this._returnCurrentPeriod(week);

        if (currentPeriod && currentPeriod.Sign) {
            const isSign = isEmpty(currentPeriod) ? false : !!currentPeriod.Sign.Image;

            let ticketStatus = 'N';
            for (let i = 0; i < data.length; i++) {
                if (data[i].FlaggedQuestion && data[i].Response === 'Yes') {
                    ticketStatus = 'U';
                }
            }
            const dailyQuestionUserResponse = {
                User: user._id,
                TicketStatus: ticketStatus,
                Project: currentTimeCard.currentProject ? currentTimeCard.currentProject.id : null,
                ProjectManager: '',
                QuestionCategory: latestDailyQuestionsTitle || null,
                Response: data,
                Date: new Date(),
            };

            submitDailyQuestion(dailyQuestionUserResponse);
            if (isSign) {
                this.setState({
                    showConfirmationDialog: true,
                    confirmationText: 'You have not signed off on last weeks timecard. Please do this ASAP',
                });
            }
        }
    };

    handleModifyPress = () => {
        // open confirmation dialog
        const { dailyReportIsChanged, dailyReportIsSaving, currentReport } = this.props;
        let confirmationText = '';
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'department' : 'project';
        if ((dailyReportIsChanged || currentReport) && !dailyReportIsSaving) {
            confirmationText =
                // eslint-disable-next-line max-len
                'It looks like you have a daily report in progress. Please go back and save or cancel the report before switching projects';
            this.setState({
                confirmationText,
                showRemindersConfirmationDialog: true,
            });
        } else {
            this.setState({
                showModifyConfirmationDialog: true,
                confirmationText: `This will replace the ${projectTitle} you are currently clocked in to with a different ${projectTitle}`,
            });
        }
    };

    clearConfirmation = () => {
        this.setState({
            showModifyConfirmationDialog: false,
            showConfirmationDialog: false,
            showRemindersConfirmationDialog: false,
            showPermissionsDialog: false,
            isSwitch: false,
            confirmationTitle: '',
            confirmationText: '',
        });
    };

    handleModifyTimeCardConfirmationPress = () => {
        const { navigation, currentTimeCard, setTimecardChange } = this.props;

        this.setState({
            showModifyConfirmationDialog: false,
            confirmationTitle: '',
            confirmationText: '',
        });
        setTimecardChange(true, cloneDeep(currentTimeCard));
        navigation.navigate('ClockIn');
    };

    changeProject(projectId) {
        const { changeProject } = this.props;
        getProject(projectId).then((r) => {
            changeProject(r[0]);
        });
    }

    changeCostCode(costCodeId) {
        const { getCostCode, changeCostCode } = this.props;
        getCostCode(costCodeId).then((r) => {
            changeCostCode(r);
        });
    }

    async postUnsavedTimeCards(isSilent) {
        const { user, unsavedCards, timeCardsIsSyncing, syncTimeCard, firstPayPeriod } = this.props;

        if (unsavedCards && unsavedCards.length && !timeCardsIsSyncing) {
            return syncTimeCard(unsavedCards, user, firstPayPeriod, isSilent);
        }

        return true;
    }

    handleScreenDidFocus = () => {
        const { setLoading, setBreakOutLoading, navigation } = this.props;
        setLoading(false);
        setBreakOutLoading(false);

        // check if we coming from forgot clock in TCM create
        const isForgotClockIn = navigation.getParam('isForgotClockIn', false);
        const selectedProject = navigation.getParam('project', null);
        const selectedCostCode = navigation.getParam('costCode', null);
        if (isForgotClockIn) {
            navigation.setParams({ isForgotClockIn: false });
            this.clockIn(selectedProject, selectedCostCode);
        }
    };

    _returnClock() {
        const { timer } = this.state;
        if (timer) {
            return formatTimeFromSeconds(timer);
        }
        return '00:00:00';
    }

    _returnActualTime() {
        const { timer } = this.state;
        if (timer) {
            return moment().format('hh:mm:ss a');
        }
        return '00:00:00';
    }

    _returnTotalClock() {
        const { timer, total } = this.state;
        if (isEmpty(total)) {
            return formatTimeFromSeconds(timer + total);
        }
        return '00:00:00';
    }

    _returnCurrentPeriod(week) {
        const { groupedCards } = this.props;
        let currentPeriod = null;
        if (!isEmpty(groupedCards)) {
            groupedCards.forEach((period) => {
                const { StartTime } = period;
                if (moment(StartTime).week() === week) {
                    currentPeriod = period;
                }
            });
        }
        return currentPeriod;
    }

    _returnBottomContainer() {
        const { navigation, currentTimeCard, isBreakInLoading, user } = this.props;
        const { enableChangeProject } = this.state;
        const { isClockIn, currentProject, isLoading } = currentTimeCard;
        const projectTitle = Config.USE_DEPARTMENTS_JOBS ? 'DEPARTMENT' : 'PROJECT';
        if (isClockIn) {
            return (
                <View style={{ width: '80%' }}>
                    <View style={s.buttonWrapper}>
                        <View
                            style={{
                                flexDirection: 'row',
                                width: '50%',
                            }}
                        >
                            <View style={{ width: '99%' }}>
                                <PrimaryButton
                                    onPress={() => this.clockOutCheck()}
                                    isLoading={isLoading}
                                    disabled={isBreakInLoading}
                                    title="CLOCK OUT"
                                    attention
                                    md
                                />
                            </View>
                            <View
                                style={{
                                    width: '99%',
                                    marginLeft: '2%',
                                }}
                            >
                                <PrimaryButton
                                    isLoading={isBreakInLoading}
                                    disabled={isLoading}
                                    breakButton
                                    onPress={this.breakStart}
                                    title="GO ON BREAK"
                                    outline
                                    md
                                />
                            </View>
                        </View>
                        <PrimaryButton
                            disabled={isLoading || isBreakInLoading}
                            onPress={() => navigation.navigate('ClockInDescription')}
                            title="DESCRIPTION / WORK ORDER"
                            outline
                            md
                        />

                        {enableChangeProject && (
                            <PrimaryButton
                                disabled={isLoading || isBreakInLoading}
                                onPress={() => {
                                    this.setState({
                                        isSwitch: true,
                                    });
                                    this.clockOutCheck();
                                }}
                                title={`SWITCH ${projectTitle}S`}
                                outline
                                md
                            />
                        )}

                        {enableChangeProject && (
                            <PrimaryButton
                                disabled={isLoading || isBreakInLoading}
                                onPress={this.handleModifyPress}
                                title={`MODIFY CURRENT ${projectTitle}`}
                                outline
                                md
                            />
                        )}
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('ProjectInfo')}>
                        <View style={s.currentProjectWrapper}>
                            <Text style={s.currentProjectLabel}>CURRENTLY TRACKING</Text>
                            <Text style={s.currentProjectName}>{currentProject && currentProject.ProjectName}</Text>
                            {currentProject &&
                                currentTimeCard &&
                                currentProject.ProjectType === 'W' &&
                                currentTimeCard.workOrderNumber > 0 && (
                                    <Text style={s.currentProjectName}>{currentTimeCard.workOrderNumber}</Text>
                                )}
                            <Icon name="chevron-right" size={28} style={s.currentProjectChevron} />
                        </View>
                    </TouchableOpacity>
                </View>
            );
        }

        const hasForgotToClockInAccess = hasAccessTo(user, null, Config.ALLOW_FORGOT_CLOCK_IN_PERM);
        return (
            <View style={[s.buttonWrapper, { width: '80%' }]}>
                <PrimaryButton
                    onPress={user && user.isActive && !user.isTrialExpired ? this.handleClockInPress : this.logout}
                    title="CLOCK IN"
                    customStyle={s.marginBottom20}
                />
                {hasForgotToClockInAccess && (
                    <PrimaryButton
                        onPress={user && user.isActive && !user.isTrialExpired ? this.forgetToClockIn : this.logout}
                        title="FORGOT TO CLOCK IN"
                        breakButtonFull
                    />
                )}
            </View>
        );
    }

    render() {
        const {
            showConfirmationDialog,
            showModifyConfirmationDialog,
            showRemindersConfirmationDialog,
            showNotSignedTimecardConfirmationDialog,
            showPermissionsDialog,
            confirmationTitle,
            confirmationText,
            isSwitch,
            isLoading,
            showLocationMessage,
        } = this.state;
        const {
            navigation,
            currentTimeCard,
            isDataLoading,
            isAutoLoginLoading,
            isDailyQuestionActive,
            latestDailyQuestions,
            latestDailyQuestionsTitle,
        } = this.props;

        const hideUINavigation = navigation.getParam('hideUINavigation');

        if (!isDailyQuestionActive && currentTimeCard.isClockIn) {
            return (
                <ImageBackground source={Img.bg} style={[s.wrapper]}>
                    <NavigationEvents onDidFocus={this.handleScreenDidFocus} />
                    <TopGradient />
                    <NavigationBar {...this.props} title="CLOCK" menuIcon={!hideUINavigation} />
                    {isLoading || isDataLoading ? (
                        <ActivityIndicator
                            color={Color.blue}
                            size={40}
                            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                        />
                    ) : (
                        <ScrollView>
                            <View style={[s.mainContainer]}>
                                <Text style={s.timer}>{this._returnActualTime()}</Text>
                                <Text style={s.timer}>{this._returnClock()}</Text>
                                <Text style={s.timerGrey}>{this._returnTotalClock()}</Text>
                                {this._returnBottomContainer()}
                                <ConfirmationDialog
                                    isOpen={showConfirmationDialog}
                                    onCancel={this.clearConfirmation}
                                    onPress={() => {
                                        this.confirmationOnPress(
                                            isSwitch ? CONFIRMATION_TYPES.SWITCH : CONFIRMATION_TYPES.CLOCK_OUT,
                                        );
                                    }}
                                    title={confirmationTitle}
                                    text={confirmationText}
                                />
                                <ConfirmationDialog
                                    isOpen={showModifyConfirmationDialog}
                                    onCancel={this.clearConfirmation}
                                    onPress={this.handleModifyTimeCardConfirmationPress}
                                    text={confirmationText}
                                />
                                <ConfirmationDialog
                                    isOpen={showRemindersConfirmationDialog}
                                    onPress={this.clearConfirmation}
                                    text={confirmationText}
                                    confirmLabel="OK"
                                />
                                <OutstandingItemDialog
                                    isOpen={showNotSignedTimecardConfirmationDialog}
                                    onCancel={() => {
                                        this.setState({ showNotSignedTimecardConfirmationDialog: false });
                                    }}
                                    onPress={() => this.confirmationOnPress(CONFIRMATION_TYPES.GO_TO_OUTSTANDING_ITEMS)}
                                    text="You have outstanding items that need to be submitted"
                                    goButtonText="GO TO OUTSTANDING ITEMS"
                                    cancelButtonText="SNOOZE OUTSTANDING ITEMS"
                                />
                                {/* <OutstandingItemDialog
                    isOpen={confirmationDialogThree}
                    onCancel={() => {
                      this.clockOutCheck()
                      this.setState({ confirmationDialogThree: false })}}
                    onPress={() => this.confirmationOnPress(CONFIRMATION_TYPES.CREATE_DAILY_REPORT)}
                    text={'Do you want to create your daily report before clocking out?'}
                    goButtonText={'CREAT DAILY REPORT'}
                    cancelButtonText={'SNOOZE DAILY REPORT'}
                  /> */}
                                <DailyQuestionDialog
                                    isOpen={isDailyQuestionActive}
                                    onSubmit={this.submitDailyQuestions}
                                    data={latestDailyQuestions}
                                    title={latestDailyQuestionsTitle}
                                />
                            </View>
                        </ScrollView>
                    )}
                </ImageBackground>
            );
        }
        return (
            <ImageBackground source={Img.bg} style={[s.wrapper]}>
                <NavigationEvents onDidFocus={this.handleScreenDidFocus} />
                <TopGradient />
                <NavigationBar {...this.props} title="CLOCK" menuIcon={!hideUINavigation} />
                {isLoading || isDataLoading || isAutoLoginLoading ? (
                    <ActivityIndicator
                        color={Color.blue}
                        size={40}
                        style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                    />
                ) : (
                    <ScrollView>
                        <View style={[s.mainContainer]}>
                            <Text style={s.timer}>{this._returnClock()}</Text>
                            <Text style={s.timerGrey}>{this._returnTotalClock()}</Text>
                            {this._returnBottomContainer()}
                            <ConfirmationDialog
                                isOpen={showConfirmationDialog}
                                onCancel={() => {
                                    this.setState({
                                        showConfirmationDialog: false,
                                        isSwitch: false,
                                    });
                                }}
                                onPress={() => this.confirmationOnPress()}
                                text={confirmationText}
                            />
                            <DailyQuestionDialog
                                isOpen={isDailyQuestionActive}
                                onSubmit={this.submitDailyQuestions}
                                data={latestDailyQuestions}
                                title={latestDailyQuestionsTitle}
                            />
                            <ConfirmationDialog
                                isOpen={showLocationMessage}
                                onPress={() => {
                                    this.setState({ showLocationMessage: false });
                                    this.clockIn();
                                }}
                                // eslint-disable-next-line max-len
                                text="Trades2Work collects location data to enable the clocking in and out function, getting the weather for reports, even when the app is closed or not in use while clocked in."
                                confirmLabel="OK"
                            />
                            <ConfirmationDialog
                                isOpen={showPermissionsDialog}
                                onCancel={this.clearConfirmation}
                                onPress={() => this.confirmationOnPress(CONFIRMATION_TYPES.PERMISSIONS)}
                                text={confirmationText}
                                confirmLabel="Go to settings"
                            />
                        </View>
                    </ScrollView>
                )}
            </ImageBackground>
        );
    }
}

const mapStateToProps = ({ account, currentTimeCard, timeCards, dailyReports }) => ({
    currentTimeCard,
    user: account.user,
    isAutoLoginLoading: account.isAutoLoginLoading,
    cachedPhoto: account.cachedPhoto,
    groupedCards: timeCards.groupedCards,
    isLoading: timeCards.isLoading,
    cleanUp,
    currentReport: dailyReports.currentReport,
    dailyReportIsChanged: dailyReports.isChanged,
    dailyReportIsSaving: dailyReports.isSaveLoading,
    latestDailyQuestions: timeCards.dailyQuestions,
    latestDailyQuestionsTitle: timeCards.dailyQuestionsTitle,
    lastTimeCard: timeCards.lastTimeCard,
    unsavedCards: timeCards.unsavedCards,
    timeCardsIsSyncing: timeCards.timeCardsIsSyncing,
    isPhotoUploading: account.isPhotoUploading,
    isDataLoading: currentTimeCard.isDataLoading,
    isBreakInLoading: currentTimeCard.isBreakInLoading,
    isDailyQuestionActive: timeCards.isDailyQuestionActive,
    firstPayPeriod: timeCards.firstPayPeriod,
    settings: account.settings,
    generalRules: account.settings.generalRules,
    costCodes: account.costCodes,
    projects: account.projects,
});

const mapDispatchToProps = (dispatch) => {
    const bindActions = bindActionCreators(
        {
            clockOut,
            breakIn,
            breakOut,
            clockIn,
            syncTimeCard,
            getTimeCards,
            setCurrentProject,
            setCurrentCostCode,
            setDescription,
            setWorkOrderNumber,
            setWorkOrderYear,
            setSwitch,
            submitDailyQuestion,
            changeClockInTime,
            changeProject,
            getCostCode,
            changeCostCode,
            adminClockOut,
            changeBreakTime,
            cleanUpTimeCard,
            getDailyQuestions,
            saveProfilePhoto,
            profilePhotoUploading,
            profilePhotoUploadingFinish,
            uploadImage,
            cacheProfilePhoto,
            setTimecardChange,
            setLoading,
            setBreakOutLoading,
            userUpdate,
            setUserViewingReports,
            userLogin,
            userLogOut,
            getGeneralRulesSettings,
            getTimeCardRules,
            selectTimeCardEdit,
        },
        dispatch,
    );
    bindActions.dispatch = dispatch;
    return bindActions;
};

export default connect(mapStateToProps, mapDispatchToProps)(ClockScreen);
