import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View, Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { formatTimeFromSeconds } from '../../../utils/helpers';
import s from './styles';
import { Color } from '../../../theme';

export default class Weeks extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        cards: PropTypes.array,
        payPeriods: PropTypes.array,
        user: PropTypes.object,
        lastGetTimeCardsStartDate: PropTypes.string,
        isTimeCardsReplaced: PropTypes.bool,
        getTimeCardsByPayPeriod: PropTypes.func,
        settings: PropTypes.object,
    };

    state = {
        week: moment().week(),
        startOfWeek: 1, // Default to Monday
        weekOption: 'default', // Option for "Use Default" or "Use Company Week"
    };

    componentDidUpdate(prevProps) {
        const { isTimeCardsReplaced } = this.props;
        if (isTimeCardsReplaced !== prevProps.isTimeCardsReplaced && isTimeCardsReplaced) {
            this.setState({ week: moment().week() });
        }
    }

    _returnCurrentWeek() {
        const { week, startOfWeek } = this.state;
        const weekStart = moment().week(week).startOf('isoWeek').weekday(startOfWeek);
        const weekEnd = moment(weekStart).add(6, 'days');
        return `${weekStart.format('MMM DD')} - ${weekEnd.format('MMM DD')}`;
    }

    _returnWeekDays() {
        const { week, startOfWeek } = this.state;
        const { cards } = this.props;
        const weekDays = [];
        weekDays.push(
            <View key="header" style={s.heading}>
                <Text style={{ width: 100 }} />
                <Text style={s.reg}>REG</Text>
                <Text style={s.pto}>PTO</Text>
            </View>,
        );
        const weekStart = moment().week(week).startOf('isoWeek').weekday(startOfWeek);
        for (let i = 0; i < 7; i++) {
            const weekDay = moment(weekStart).add(i, 'days');

            const formattedWeekDay = weekDay.format('YYYY-MM-DD');
            let regDayTotal = 0;
            let ptoDayTotal = 0;
            cards.forEach((card) => {
                if (formattedWeekDay === card.date) {
                    regDayTotal += card.totalTime;
                    if (card.type) {
                        ptoDayTotal += card.ptoTime * 3600;
                    }
                }
            });

            weekDays.push(
                <View style={s.weekDay} key={`${formattedWeekDay}-${i}`}>
                    <View style={{ flexDirection: 'row', width: 100 }}>
                        <Text style={s.weekDayDate}>{weekDay.format('MM/DD')}</Text>
                        <Text style={s.weekDayName}>{weekDay.format('dddd')}</Text>
                    </View>
                    <Text
                        style={[
                            ptoDayTotal > 0
                                ? (s.weekDayTimeRegPadding, s.weekDayTime_inactiveRegPadding)
                                : (s.weekDayTimeReg, s.weekDayTime_inactiveReg),
                        ]}
                    >
                        {regDayTotal > 0 ? formatTimeFromSeconds(regDayTotal, false) : '-'}
                    </Text>
                    <Text style={ptoDayTotal > 0 ? s.weekDayTimePto : s.weekDayTime_inactivePto}>
                        {ptoDayTotal > 0 ? formatTimeFromSeconds(ptoDayTotal, false) : '-'}
                    </Text>
                </View>,
            );
        }

        return weekDays;
    }

    _returnWeekPaidTimeTotal() {
        const { cards } = this.props;
        const { week, startOfWeek } = this.state;
        let totalPaidTimeOff = 0;

        for (let i = 0; i < 7; i++) {
            const weekDay = moment()
                .week(week)
                .weekday((startOfWeek + i) % 7); // Adjust based on startOfWeek
            const formattedWeekDay = weekDay.format('YYYY-MM-DD');
            let totalTimeOff = 0;
            cards.forEach((card) => {
                if (card.type && formattedWeekDay === card.date) {
                    totalTimeOff += card.ptoTime * 3600;
                }
            });
            totalPaidTimeOff += totalTimeOff;
        }
        return formatTimeFromSeconds(totalPaidTimeOff);
    }

    _returnWeekTotal() {
        const weekTotalTime = this._returnTimeCardWorkTimesInSeconds();
        return formatTimeFromSeconds(weekTotalTime);
    }

    _returnTimeCardWorkTimesInSeconds() {
        const { week, startOfWeek } = this.state;
        const { cards } = this.props;

        const weekStart = moment().week(week).startOf('isoWeek').weekday(startOfWeek); // Adjust for selected start of week
        const weekEnd = moment().week(week).endOf('isoWeek').weekday(startOfWeek); // Adjust for selected start of week

        let weekTotalTime = 0;
        for (let i = 0; i < cards.length; i++) {
            if (moment(cards[i].date).isBetween(weekStart, weekEnd, null, '[]')) {
                weekTotalTime += cards[i].totalTime;
            }
        }
        return weekTotalTime;
    }

    nextWeek = () => {
        const { week } = this.state;
        this.setState({ week: week + 1 });
    };

    previousWeek = () => {
        const { week } = this.state;
        const { payPeriods, user, getTimeCardsByPayPeriod, lastGetTimeCardsStartDate } = this.props;
        this.setState({ week: week - 1 });
        const weekStart = moment()
            .week(week - 1)
            .startOf('isoWeek')
            .weekday(this.state.startOfWeek); // Adjust for selected start of week
        let currentPeriod = null;
        for (let period of payPeriods) {
            const start = moment(period.StartDate);
            const end = moment(period.EndDate);
            if (weekStart.isBetween(start, end, null, '[]')) {
                currentPeriod = period;
            }
        }

        if (
            (currentPeriod && lastGetTimeCardsStartDate === null) ||
            (currentPeriod && moment(lastGetTimeCardsStartDate).isAfter(moment(currentPeriod.StartDate)))
        ) {
            getTimeCardsByPayPeriod({
                user,
                startDate: currentPeriod.StartDate,
                endDate: currentPeriod.EndDate,
            });
        }
    };

    // Fix for _isTimeCardModifications method
    _isTimeCardModifications = () => {
        const { week } = this.state;
        const { cards } = this.props;
        let isTimeCardModifications = false;

        for (let i = 1; i <= 7; i++) {
            const weekDay = moment().week(week).weekday(i);
            const formattedWeekDay = weekDay.format('YYYY-MM-DD');

            cards.forEach((card) => {
                if (formattedWeekDay === card.date) {
                    card.data.forEach((entry) => {
                        if (entry.timeCardModifications) {
                            isTimeCardModifications = true;
                        }
                    });
                }
            });
        }

        return isTimeCardModifications;
    };

    handleWeekOptionChange = (option, startOfWeek) => {
        this.setState({ weekOption: option });
        if (option === 'default') {
            this.setState({ startOfWeek: 1 });
        } else {
            this.setState({ startOfWeek: startOfWeek });
        }
    };

    render() {
        const { isLoading, settings, payPeriods } = this.props;
        let startOfWeek = settings?.timeCardRules?.workWeekStart
            ? settings.timeCardRules.workWeekStart
            : payPeriods && payPeriods.length > 0
            ? new Date(payPeriods[payPeriods.length - 1].StartDate).getDay()
            : 1;

        const isTimeCardModifications = this._isTimeCardModifications();
        return (
            <View style={s.mainContainer}>
                <View style={s.weekMenu}>
                    <TouchableOpacity onPress={this.previousWeek}>
                        <Icon name="chevron-left" size={28} style={s.weekMenuIcon} />
                    </TouchableOpacity>
                    <Text style={s.title}>{this._returnCurrentWeek()}</Text>
                    <TouchableOpacity onPress={this.nextWeek}>
                        <Icon name="chevron-right" size={28} style={s.weekMenuIcon} />
                    </TouchableOpacity>
                </View>
                {startOfWeek !== 1 ? (
                    <View style={s.optionButtonsContainer}>
                        <TouchableOpacity
                            style={[s.optionButton, this.state.weekOption === 'default' && s.selectedButton]}
                            onPress={() => this.handleWeekOptionChange('default', startOfWeek)}
                        >
                            <Text style={s.optionButtonText}>Use Default</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[s.optionButton, this.state.weekOption === 'company' && s.selectedButton]}
                            onPress={() => this.handleWeekOptionChange('company', startOfWeek)}
                        >
                            <Text style={s.optionButtonText}>Use Company Week</Text>
                        </TouchableOpacity>
                    </View>
                ) : null}

                {isLoading ? (
                    <ActivityIndicator
                        size={35}
                        color={Color.blue}
                        style={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start', paddingTop: '8rem' }}
                    />
                ) : (
                    <View style={s.formBlock}>
                        <View style={s.formHeaderBlock}>
                            <Text style={s.formBlockTitle}>{this._returnCurrentWeek()}</Text>
                        </View>

                        <View style={s.formBlockDates}>{this._returnWeekDays()}</View>

                        <View style={s.totalTime}>
                            <View style={{ width: 100 }}></View>
                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotalReg}>{this._returnWeekTotal()}</Text>
                            </View>
                            <View style={s.formBlockTotal}>
                                <Text style={s.weekTotal}>{this._returnWeekPaidTimeTotal()}</Text>
                            </View>
                        </View>
                        {isTimeCardModifications && (
                            <View>
                                <Text style={s.timeCardEdits}>The selected week has a time card modification</Text>
                            </View>
                        )}
                    </View>
                )}
            </View>
        );
    }
}
