import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import Api from '../../utils/apiMiddleware';
import { generateId } from '../../utils/helpers';
import { Config } from '../../config';

import { Color } from '../../theme';

const initialState = {
    isLoading: false,
    isTimeCardEdit: false,
    id: null,
    date: null,
    timeCardId: null,
    timeCardDayId: null,
    project: null,
    projectDetails: null,
    type: 'C',
    costCode: null,
    costCodeDetails: null,
    note: '',
    breaks: [],
    clockIn: null,
    clockOut: null,
    workOrder: '',
    description: '',
    adminApprover: [],
    unsaved: [],
    projectName: null,
    bulkTimeCardEdits: [],
    bulkTimeCardEditsStartDate: null,
    bulkTimeCardEditsEndDate: null,
};

// ------------------------------------
// Actions
// ------------------------------------
export function selectTimeCardEdit(card, type, addCurrentDate = false) {
    const timeCard = cloneDeep(initialState);
    delete timeCard.isTimeCardEdit;
    if (!isEmpty(card)) {
        timeCard.project = card.Project;
        timeCard.projectName = card.ProjectName;
        timeCard.costCode = card.CostCode;
        timeCard.date = moment(card.StartTime).toDate();
        timeCard.clockIn = moment(card.StartTime).toDate();
        timeCard.clockOut = moment(card.EndTime).toDate();
        timeCard.breaks = card.Breaks;
        timeCard.workOrder = card.WorkOrder;
        timeCard.description = card.Description;
        timeCard.edit = true;
        if (type === 2) {
            timeCard.id = card.id;
            timeCard.Id = card.id;
            timeCard.timeCardId = card.TimeCard;
            timeCard.timeCardDayId = card.TimeCardDay;
        } else {
            timeCard.timeCardId = card.TimeCardId;
            timeCard.timeCardDayId = card.WorkTimeId;
        }
    }

    if (addCurrentDate) {
        timeCard.date = moment().toDate();
        timeCard.clockOut = moment().set({ second: 0, millisecond: 0 }).toDate();
    }

    return (dispatch) => {
        return dispatch({
            type: CNST.TIME_CARD_EDIT.SELECT_TIMECARD,
            timeCard,
            card,
        });
    };
}

export function changeCardEdit(data = {}) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.CHANGE,
            data,
        });
    };
}

export function changeBulkTimeCardEdit(data = {}) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.BULK_EDIT_CHANGE,
            data,
        });
    };
}

export function changeBulkTimeCardBreak(data = {}) {
    const { time, breaks, index, type, timeCardLocation } = data;
    const newBreaks = cloneDeep(breaks);
    if (type === 1) {
        newBreaks[index].StartTime = time;
    } else {
        newBreaks[index].EndTime = time;
    }
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.BULK_EDIT_CHANGE,
            data: { index: timeCardLocation, breaks: newBreaks },
        });
    };
}

export function changeBreak(data = {}) {
    const { time, breaks, index, type } = data;
    const newBreaks = cloneDeep(breaks);
    if (type === 1) {
        newBreaks[index].StartTime = time;
    } else {
        newBreaks[index].EndTime = time;
    }
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.CHANGE_BREAKS,
            newBreaks,
        });
    };
}

export function addBreak(data = {}) {
    const { breaks, date } = data;
    const newBreaks = cloneDeep(breaks);
    newBreaks.push({
        StartTime: moment(date).toDate(),
        EndTime: moment(date).add(30, 'm').toDate(),
    });
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.CHANGE_BREAKS,
            newBreaks,
        });
    };
}

export function addBulkTimeCardBreak(data = {}) {
    const { breaks, date, index } = data;
    const newBreaks = cloneDeep(breaks);
    newBreaks.push({
        StartTime: moment(date).toDate(),
        EndTime: moment(date).add(30, 'm').toDate(),
    });
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.BULK_EDIT_CHANGE,
            data: { index, breaks: newBreaks },
        });
    };
}

export function removeBulkTimeCardBreak(data = {}) {
    const { breaks, index, timeCardLocation } = data;
    const newBreaks = cloneDeep(breaks);
    newBreaks.splice(index, 1);
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.BULK_EDIT_CHANGE,
            data: { index: timeCardLocation, breaks: newBreaks },
        });
    };
}

export function removeBreak(data = {}) {
    const { breaks, index } = data;
    const newBreaks = cloneDeep(breaks);
    newBreaks.splice(index, 1);
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.CHANGE_BREAKS,
            newBreaks,
        });
    };
}

export function changeBulkTimeCardStartDate(data = {}) {
    const { date } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.SELECT_START_TIME,
            date,
        });
    };
}

export function changeBulkTimeCardEndDate(data = {}) {
    const { date } = data;
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.SELECT_END_TIME,
            date,
        });
    };
}

export function saveTimeCardEdit(user, card, totalTime, projectName) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.SAVE_TIMECARD.LOADING,
        });

        let cardEditItem = null;
        const { unsavedId } = card;
        if (card.unsavedId) {
            cardEditItem = { ...card };
            delete cardEditItem.unsavedId;
        } else {
            const startTime = moment(card.date)
                .set({
                    hour: moment(card.clockIn).get('hour'),
                    minute: moment(card.clockIn).get('minute'),
                    second: 0,
                    millisecond: 0,
                })
                .toISOString();
            const endTime = moment(card.date)
                .set({
                    hour: moment(card.clockOut).get('hour'),
                    minute: moment(card.clockOut).get('minute'),
                    second: 0,
                    millisecond: 0,
                })
                .toISOString();
            cardEditItem = {
                TimeCard: card.timeCardId,
                TimeCardDay: card.timeCardDayId,
                Project: card.project,
                ProjectName: projectName || card.projectName,
                ClockInType: card.type,
                CostCode: card.costCode,
                TimeAndMaterial: card.timeAndMaterial ? card.timeAndMaterial : '',
                StartTime: startTime,
                EndTime: endTime,
                Breaks: card.breaks,
                TotalTime: totalTime,
                Status: 'U',
                UserComment: card.note,
                AdminFeedback: '',
                Change: false,
                AdminApprover: [],
                User: user._id,
                WorkOrderNumber: card.workOrder,
                Description: card.description,
                PreviousTimeCardChange: {
                    Project: null,
                    ClockInType: '',
                    CostCode: null,
                    TimeAndMaterial: null,
                    StartTime: '',
                    EndTime: '',
                    Duration: {
                        WorkTimeHours: '',
                        WorkTime: '',
                        BreakTimeHours: '',
                        BreakTime: '',
                    },
                    WorkOrderNumber: '',
                    Description: '',
                    Breaks: [],
                },
            };
        }

        return NetInfo.fetch()
            .then((state) => {
                const { isConnected } = state;

                if (isConnected) {
                    return Api()
                        .put(`/sources/${Config.TIMECARD_MODIFICATIONS}/data/${card.id ? card.id : 'new'}`, {
                            data: cardEditItem,
                        })
                        .then((response) => {
                            showMessage({
                                message: 'Success',
                                description: 'Your time card modification has been received!',
                                duration: 10000,
                                backgroundColor: Color.turquoise,
                                animationDuration: 0,
                                hideOnPress: true,
                                hideStatusBar: true,
                            });
                            dispatch({
                                type: CNST.TIME_CARD_EDIT.SAVE_TIMECARD.SUCCESS,
                                item: response,
                                unsavedId,
                            });
                            return true;
                        })
                        .catch((error) => {
                            let errorMessage =
                                'An error occurred while saving time card modification. Please try again.';
                            if (error && error.statusCode === 403) {
                                errorMessage = error.message;
                            }
                            showMessage({
                                message: 'Error',
                                description: errorMessage,
                                type: 'danger',
                                duration: 10000,
                                animationDuration: 700,
                                hideOnPress: true,
                                hideStatusBar: true,
                                backgroundColor: Color.red,
                            });
                            dispatch({
                                type: CNST.TIME_CARD_EDIT.SAVE_TIMECARD.ERROR,
                                error,
                            });
                            console.log('error', error);
                            return false;
                        });
                }

                showMessage({
                    message:
                        // eslint-disable-next-line max-len
                        'No data connection detected. Your time card modification has been saved on your device and will be sent when you have a data connection.',
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 10000,
                    animationDuration: 0,
                    hideOnPress: true,
                    hideStatusBar: true,
                });

                dispatch({
                    type: CNST.TIME_CARD_EDIT.SAVE_TIMECARD.ERROR,
                    cardEditItem,
                    unsavedId,
                    id: card.id,
                });

                return true;
            })
            .catch(() => {
                showMessage({
                    message: 'Error',
                    description: 'An error occurred while saving time card modification. Please try again.',
                    type: 'danger',
                    duration: 10000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
                dispatch({
                    type: CNST.TIME_CARD_EDIT.SAVE_TIMECARD.ERROR,
                });
                return false;
            });
    };
}

export function setTimeCardEditProject(project) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.SET_PROJECT,
            project,
        });
    };
}

export function setBulkTimeCardEditProject(project, index) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.BULK_EDIT_CHANGE,
            data: { index, project: project.id, projectDetails: project },
        });
    };
}

export function setTimeCardEditCostCode(code) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.SET_COSTCODE,
            code,
        });
    };
}

export function setBulkTimeCardEditCostCode(code, index) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.BULK_EDIT_CHANGE,
            data: { index, costCode: code },
        });
    };
}

export function cleanTimeCardEdit() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.CLEAN_UP,
        });
    };
}

export function addBulkTimeCardEdits() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.ADD_DATES,
        });
    };
}

export function clearBulkTimeCardEdits() {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.CLEAR_BULK_TIME_ENTIRES,
        });
    };
}

export function addBulkTimeCardEditsAddExtraDay(day, index) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.ADD_EXTRA_DAY,
            data: { day, index },
        });
    };
}

export function removeBulkTimeCardEditsRemoveExtraDay(index) {
    return (dispatch) => {
        dispatch({
            type: CNST.TIME_CARD_EDIT.REMOVE_EXTRA_DAY,
            index,
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const updateBulkTimeCardEdits = (bulkTimeCardEdits, index, newData) => {
    return bulkTimeCardEdits.map((item, i) => (i === index ? { ...item, ...newData } : item));
};

export default function timeCardEdit(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.TIME_CARD_EDIT.BULK_EDIT_CHANGE: {
            const { index, ...newData } = action.data;
            return {
                ...state,
                bulkTimeCardEdits: updateBulkTimeCardEdits(state.bulkTimeCardEdits, index, newData),
            };
        }

        case CNST.TIME_CARD_EDIT.CLEAR_BULK_TIME_ENTIRES: {
            return {
                ...state,
                bulkTimeCardEdits: [],
                bulkTimeCardEditsStartDate: null,
                bulkTimeCardEditsEndDate: null,
            };
        }
        case CNST.TIME_CARD_EDIT.ADD_DATES: {
            const { bulkTimeCardEditsStartDate, bulkTimeCardEditsEndDate } = state;

            if (!bulkTimeCardEditsStartDate || !bulkTimeCardEditsEndDate) {
                return state;
            }

            const start = moment(bulkTimeCardEditsStartDate);
            const end = moment(bulkTimeCardEditsEndDate);

            const newEntries = [];
            for (let date = start.clone().startOf('day'); date.isSameOrBefore(end); date.add(1, 'days')) {
                newEntries.push({
                    id: null,
                    date: date.clone().toISOString(), // use clone() to avoid mutating the original date object
                    timeCardId: null,
                    timeCardDayId: null,
                    project: null,
                    projectDetails: null,
                    type: 'C',
                    costCode: null,
                    note: '',
                    breaks: [],
                    clockIn: null,
                    clockOut: null,
                    workOrder: '',
                    description: '',
                    adminApprover: [],
                    showRemoveButton: false,
                    showAddButton: true,
                });
            }

            return {
                ...state,
                bulkTimeCardEdits: [...newEntries],
            };
        }

        case CNST.TIME_CARD_EDIT.REMOVE_EXTRA_DAY: {
            const { index } = action;
            const { bulkTimeCardEdits } = state;

            // Clone the current bulkTimeCardEdits array
            const updatedEntries = [...bulkTimeCardEdits];

            // Remove the entry at the specified index
            updatedEntries.splice(index, 1);

            // Return the updated state
            return {
                ...state,
                bulkTimeCardEdits: updatedEntries,
            };
        }
        case CNST.TIME_CARD_EDIT.ADD_EXTRA_DAY: {
            const { index, day } = action.data;
            const { bulkTimeCardEdits } = state;
            // Clone the current bulkTimeCardEdits array
            const updatedEntries = [...bulkTimeCardEdits];

            // Create a new entry with the provided date
            const newEntry = {
                id: null,
                date: day,
                timeCardId: null,
                timeCardDayId: null,
                project: null,
                projectDetails: null,
                type: 'C',
                costCode: null,
                note: '',
                breaks: [],
                clockIn: null,
                clockOut: null,
                workOrder: '',
                description: '',
                adminApprover: [],
                showAddButton: false,
                showRemoveButton: true,
            };

            // Insert the new entry at the specified index
            updatedEntries.splice(index + 1, 0, newEntry);

            // Return the updated state
            return {
                ...state,
                bulkTimeCardEdits: updatedEntries,
            };
        }

        case CNST.TIME_CARD_EDIT.SELECT_START_TIME:
            return {
                ...state,
                ...{
                    bulkTimeCardEditsStartDate: moment(action.date).startOf('day'),
                },
            };
        case CNST.TIME_CARD_EDIT.SELECT_END_TIME:
            return {
                ...state,
                ...{
                    bulkTimeCardEditsEndDate: moment(action.date).endOf('day'),
                },
            };
        case CNST.TIME_CARD_EDIT.CHANGE_BULK_BREAKS:
            return {
                ...state,
                ...{
                    breaks: action.newBreaks,
                },
            };
        case CNST.TIME_CARD_EDIT.SAVE_TIMECARD.LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case CNST.TIME_CARD_EDIT.SELECT_TIMECARD:
            return {
                ...state,
                ...{
                    ...action.timeCard,
                    isTimeCardEdit: true,
                },
            };
        case CNST.TIME_CARD_EDIT.CHANGE:
            return {
                ...state,
                ...action.data,
            };
        case CNST.TIME_CARD_EDIT.SAVE_TIMECARD.SUCCESS: {
            const { unsavedId } = action;
            let unsavedItems = null;
            if (unsavedId) {
                unsavedItems = state.unsaved.filter((item) => item.unsavedId !== unsavedId);
            } else {
                unsavedItems = [...state.unsaved];
            }
            return {
                ...state,
                ...{
                    isLoading: false,
                    editTimeCard: action.timeCard,
                    isTimeCardEdit: false,
                    unsaved: unsavedItems,
                },
            };
        }

        case CNST.TIME_CARD_EDIT.SET_PROJECT:
            return {
                ...state,
                ...{
                    project: action.project.id,
                    projectDetails: action.project,
                    projectName: action.project.ProjectName,
                },
            };
        case CNST.TIME_CARD_EDIT.SET_COSTCODE:
            return {
                ...state,
                ...{
                    costCode: action.code.id,
                    costCodeDetails: action.code,
                },
            };
        case CNST.TIME_CARD_EDIT.CHANGE_BREAKS:
            return {
                ...state,
                ...{
                    breaks: action.newBreaks,
                },
            };
        case CNST.TIME_CARD_EDIT.CLEAN_UP:
        case CNST.ACCOUNT.LOGOUT.SUCCESS: {
            return {
                ...state,
                ...cloneDeep(initialState),
                unsaved: state.unsaved,
            };
        }
        case CNST.TIME_CARD_EDIT.SAVE_TIMECARD.ERROR: {
            const { cardEditItem, unsavedId, id } = action;

            let unsavedItems = null;
            if (unsavedId && cardEditItem) {
                unsavedItems = state.unsaved.map((item) => {
                    if (item.unsavedId === unsavedId) {
                        return {
                            ...cardEditItem,
                            unsavedId,
                            id,
                        };
                    }
                    return item;
                });
            } else if (cardEditItem) {
                unsavedItems = [
                    ...state.unsaved,
                    {
                        ...cardEditItem,
                        unsavedId: generateId(),
                        id,
                    },
                ];
            } else {
                unsavedItems = [...state.unsaved];
            }

            return {
                ...state,
                isLoading: false,
                unsaved: unsavedItems,
            };
        }
        default:
            return state;
    }
}
