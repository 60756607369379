import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { cleanUp, saveTimeOffItem } from '../../../store/modules/timeOff';
import { getPtoSettings } from '../../../store/modules/account';
import {
    DropdownCalendar,
    DropdownList,
    FieldInput,
    NavigationBar,
    PrimaryButton,
    ConfirmationDialog,
} from '../../../components/index';
import { Color } from '../../../theme/index';
import s from './styles';

const LeaveTypes = {
    Sick: 'sick',
    Personal: 'personal',
    Vacation: 'vacation',
};

class PaidTimeOffItemScreen extends Component {
    static propTypes = {
        navigation: PropTypes.object,
        currentTimeOff: PropTypes.object,
        timeOffItems: PropTypes.array,
        ptoBuckets: PropTypes.object,
        payPeriods: PropTypes.array,
        user: PropTypes.object,
        settings: PropTypes.object,
        assignUsers: PropTypes.array,
        isLoading: PropTypes.bool,
        cleanUp: PropTypes.func,
        saveTimeOffItem: PropTypes.func,
        getPtoSettings: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { currentTimeOff, assignUsers } = props;
        let dates = [];

        if (!isEmpty(currentTimeOff.TimeOffDays)) {
            for (let i = 0; i < currentTimeOff.TimeOffDays.length; i++) {
                const ptoTimeOff = {
                    Date: moment(currentTimeOff.TimeOffDays[i].Date).toDate(),
                    PtoBucket: this.getSelectedPtoBucketItem(currentTimeOff.TimeOffDays[i].PtoBucket),
                    Hours: currentTimeOff.TimeOffDays[i].Hours,
                };

                dates.push(ptoTimeOff);
            }
        }

        const range = {
            from: null,
            to: null,
        };
        if (currentTimeOff.id) {
            range.from = dates[0].Date;
            range.to = dates[dates.length - 1].Date;
        }
        let pm = null;
        if (currentTimeOff.AdminApprover) {
            if (currentTimeOff) {
                for (let i = 0; i < assignUsers.length; i++) {
                    for (let j = 0; j < currentTimeOff.AdminApprover.length; j++) {
                        if (currentTimeOff.AdminApprover[j].User === assignUsers[i].id) {
                            pm = assignUsers[i];
                        }
                    }
                }
            }
        }
        this.state = {
            oldTimeOffRequest: currentTimeOff || null,
            range,
            startTime: currentTimeOff && currentTimeOff.StartTime ? moment(currentTimeOff.StartTime) : null,
            endTime: currentTimeOff && currentTimeOff.EndTime ? moment(currentTimeOff.EndTime) : null,
            comment: currentTimeOff.UserComment || '',
            assignTo: pm || [],
            confirmationDialog: false,
            confirmationText: '',
            dates,
        };
    }

    componentDidMount() {
        const { getPtoSettings } = this.props;
        getPtoSettings();
    }

    componentDidUpdate(prevProps, prevState) {
        const { range } = this.state;
        // EDIT RANGE DATES
        if (!!range.from && !!range.to && (range.from !== prevState.range.from || range.to !== prevState.range.to)) {
            this.rearrangeDates();
        }
    }

    getSelectedPtoBucketItem(ptoBucketId) {
        const { ptoBuckets } = this.props;
        const ptoBucket = ptoBuckets[ptoBucketId];
        return {
            id: ptoBucketId,
            title: ptoBucket.Name,
            internalName: ptoBucket.InternalName,
        };
    }

    setHours(hours, index) {
        const { dates } = this.state;
        const newDate = cloneDeep(dates);

        newDate[index].Hours = hours;

        this.setState({ dates: newDate });
    }

    setRange(date, type) {
        const { range } = this.state;
        const newRange = cloneDeep(range);
        if (type === 1) {
            newRange.from = moment(date).toDate();
        } else {
            newRange.to = moment(date).toDate();
        }

        this.setState({ range: newRange });
    }

    rearrangeDates() {
        const { range, dates } = this.state;
        const rangeLength = moment(range.to).diff(moment(range.from), 'days') + 1;
        const newDates = [];
        for (let i = 0; i < rangeLength; i++) {
            const dateItem = {
                Date: moment(range.from).add(i, 'days').toDate(),
                PtoBucket: null,
                Hours: null,
            };
            dates.forEach((d) => {
                if (moment(d.Date).isSame(moment(dateItem.Date), 'day')) {
                    dateItem.PtoBucket = d.PtoBucket;
                    dateItem.Hours = d.Hours;
                }
            });
            newDates[i] = dateItem;
        }
        this.setState({ dates: newDates });
    }

    setPtoBucket(item, i) {
        const { dates } = this.state;
        const newDate = cloneDeep(dates);
        newDate[i].PtoBucket = item;
        this.setState({ dates: newDate });
    }

    getActivePtoBuckets() {
        const { ptoBuckets } = this.props;

        const activePtoBuckets = [];

        for (const ptoBucket of Object.values(ptoBuckets)) {
            if (ptoBucket.IsActive) {
                activePtoBuckets.push({
                    id: ptoBucket.id,
                    title: ptoBucket.Name,
                    internalName: ptoBucket.InternalName,
                });
            }
        }

        return activePtoBuckets;
    }

    returnDay(i, ptoBuckets, totalDates) {
        const { dates } = this.state;
        const date = dates[i];

        return (
            <View key={i} style={{ width: '100%', zIndex: totalDates + 1 - i }}>
                <View style={s.dateRow}>
                    <Text style={s.dateRowTitle}>{date.Date && moment(date.Date).format('MM/DD ddd')}</Text>
                </View>
                <View style={s.timesRow}>
                    <View style={s.materialDropdown_half}>
                        <DropdownList
                            items={ptoBuckets}
                            dropStyle={[s.materialDropdown, s.materialDropdownInt]}
                            selectedItem={date.PtoBucket}
                            onItemSelect={(item) => {
                                this.setPtoBucket(item, i);
                            }}
                            title="PTO TYPE"
                            withIds
                        />
                    </View>
                    <View style={s.materialDropdown_half}>
                        <FieldInput
                            title="HOURS"
                            keyboardType="decimal-pad"
                            isNumber
                            input={{
                                onChange: (hours) => {
                                    this.setHours(hours, i);
                                },
                            }}
                            meta={{
                                error: null,
                                touched: false,
                            }}
                            initialValues={date.Hours ? date.Hours.toString() : null}
                        />
                    </View>
                </View>
            </View>
        );
    }

    _renderRangePeriod() {
        const { totalSickHours, totalPersonalHours, totalVacationHours } = this.getTotalPtoHours();
        const { personalPTOHoursAvailable, vacationPTOHoursAvailable, sickPTOHoursAvailable } =
            this.getAvailablePtoHours();
        const { dates, range, startTime, endTime } = this.state;

        const activePtoBuckets = this.getActivePtoBuckets();

        return (
            <View
                style={{
                    width: '100%',
                    marginBottom: 10,
                    zIndex: 2,
                }}
            >
                <View style={[s.timesRow, { zIndex: 2 }]}>
                    <View style={s.materialDropdown_half}>
                        <DropdownCalendar
                            containerStyle={[s.dropdownCalendar]}
                            date={range.from}
                            onSelect={(date) => this.setRange(date, 1)}
                            placeholder="FROM"
                            height={89}
                            topLabel
                            mode="date"
                            range={range}
                            startTime={startTime}
                            endTime={endTime}
                            type="1"
                        />
                    </View>
                    <View style={s.materialDropdown_half}>
                        <DropdownCalendar
                            containerStyle={[s.dropdownCalendar]}
                            date={range.to}
                            onSelect={(date) => this.setRange(date, 2)}
                            placeholder="TO"
                            height={89}
                            topLabel
                            mode="date"
                            range={range}
                            startTime={startTime}
                            endTime={endTime}
                            type="2"
                        />
                    </View>
                </View>
                {dates.length > 0 && (
                    <View style={s.rangeDaysContainer}>
                        {dates.map((d, i) => {
                            return this.returnDay(i, activePtoBuckets, dates.length);
                        })}
                    </View>
                )}

                {range.from && range.to && (
                    <View style={s.ptoTimesInfoWrapper}>
                        <View>
                            <View style={s.timeRow_range}>
                                <Text style={s.timeText}>
                                    Total sick time requested:
                                    {totalSickHours > 0 ? ` ${totalSickHours}` : ' -'}
                                </Text>
                            </View>
                            <View style={s.timeRow_range}>
                                <Text style={s.timeText}>
                                    Total personal time requested:
                                    {totalPersonalHours > 0 ? ` ${totalPersonalHours}` : ' -'}
                                </Text>
                            </View>
                            <View style={s.timeRow_range}>
                                <Text style={s.timeText}>
                                    Total vacation time requested:
                                    {totalVacationHours > 0 ? ` ${totalVacationHours}` : ' -'}
                                </Text>
                            </View>
                        </View>
                        <View>
                            <View style={s.timeRow_range}>
                                <Text style={s.timeText}>
                                    {this.getPTOHoursAvailableLabel(
                                        LeaveTypes.Sick,
                                        sickPTOHoursAvailable - totalSickHours,
                                    )}
                                </Text>
                            </View>
                            <View style={s.timeRow_range}>
                                <Text style={s.timeText}>
                                    {this.getPTOHoursAvailableLabel(
                                        LeaveTypes.Personal,
                                        personalPTOHoursAvailable - totalPersonalHours,
                                    )}
                                </Text>
                            </View>
                            <View style={s.timeRow_range}>
                                <Text style={s.timeText}>
                                    {this.getPTOHoursAvailableLabel(
                                        LeaveTypes.Vacation,
                                        vacationPTOHoursAvailable - totalVacationHours,
                                    )}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        );
    }

    getTotalPtoHours() {
        const { dates } = this.state;

        let totalSickHours = 0;
        let totalPersonalHours = 0;
        let totalVacationHours = 0;
        dates.forEach((d) => {
            if (d.PtoBucket && d.PtoBucket.internalName === LeaveTypes.Sick && d.Hours) {
                totalSickHours += d.Hours;
            }
            if (d.PtoBucket && d.PtoBucket.internalName === LeaveTypes.Personal && d.Hours) {
                totalPersonalHours += d.Hours;
            }
            if (d.PtoBucket && d.PtoBucket.internalName === LeaveTypes.Vacation && d.Hours) {
                totalVacationHours += d.Hours;
            }
        });
        return { totalSickHours, totalPersonalHours, totalVacationHours };
    }

    getAvailablePtoHours() {
        const { user } = this.props;

        const userPto = user.meta.PTO;
        const personalPTOHoursAvailable = userPto.Personal - userPto.PersonalRequested - userPto.PersonalApproved;
        const vacationPTOHoursAvailable = userPto.Vacation - userPto.VacationRequested - userPto.VacationApproved;
        const sickPTOHoursAvailable = userPto.Sick - userPto.SickRequested - userPto.SickApproved;

        return { personalPTOHoursAvailable, vacationPTOHoursAvailable, sickPTOHoursAvailable };
    }

    getPTOHoursAvailableLabel(leaveType, availableHours) {
        const { settings } = this.props;
        const ptoSettings = settings.pto ? settings.pto : {};

        const showThreshold = availableHours < 0;
        let thresholdValue = 0;

        if (leaveType === LeaveTypes.Personal && ptoSettings.personalHoursNegativeThreshold) {
            thresholdValue = ptoSettings.personalHoursNegativeThreshold;
        } else if (leaveType === LeaveTypes.Sick && ptoSettings.sickHoursNegativeThreshold) {
            thresholdValue = ptoSettings.sickHoursNegativeThreshold;
        } else if (leaveType === LeaveTypes.Vacation && ptoSettings.vacationHoursNegativeThreshold) {
            thresholdValue = ptoSettings.vacationHoursNegativeThreshold;
        }

        if (showThreshold) {
            return `Total ${leaveType} time: ${availableHours}. Total negative threshold hours available: ${thresholdValue}`;
        }

        return `Total ${leaveType} time: ${availableHours}`;
    }

    isDataValid() {
        const { dates, range } = this.state;
        const { settings } = this.props;

        if (range.to == null && range.from == null) {
            this.setState({ confirmationDialog: true, confirmationText: 'Dates range not selected' });
            return false;
        }

        const missingDatesData = [];
        for (const date of dates) {
            if (date.PtoBucket === null || date.Hours === null) {
                missingDatesData.push(moment(date.Date).format('MM/DD/YY'));
            }
        }

        if (missingDatesData.length > 0) {
            let confirmationText = 'You did not select leave type or enter hours for these date(s): ';
            confirmationText += missingDatesData.join(', ');
            this.setState({ confirmationDialog: true, confirmationText });
            return false;
        }

        const { totalSickHours, totalPersonalHours, totalVacationHours } = this.getTotalPtoHours();
        let { personalPTOHoursAvailable, vacationPTOHoursAvailable, sickPTOHoursAvailable } =
            this.getAvailablePtoHours();

        // check thresholds
        personalPTOHoursAvailable += settings.pto ? settings.pto.personalHoursNegativeThreshold : 0;
        vacationPTOHoursAvailable += settings.pto ? settings.pto.vacationHoursNegativeThreshold : 0;
        sickPTOHoursAvailable += settings.pto ? settings.pto.sickHoursNegativeThreshold : 0;

        if (totalSickHours > 0 && totalSickHours > sickPTOHoursAvailable) {
            this.setState({ confirmationDialog: true, confirmationText: 'You have exceeded the sick hours available' });
            return false;
        }

        if (totalPersonalHours > 0 && totalPersonalHours > personalPTOHoursAvailable) {
            this.setState({
                confirmationDialog: true,
                confirmationText: 'You have exceeded the personal hours available',
            });
            return false;
        }

        if (totalVacationHours > 0 && totalVacationHours > vacationPTOHoursAvailable) {
            this.setState({
                confirmationDialog: true,
                confirmationText: 'You have exceeded the vacation hours available',
            });
            return false;
        }

        return true;
    }

    isChanged(timeOff) {
        const { oldTimeOffRequest } = this.state;
        return !isEqual(oldTimeOffRequest, timeOff);
    }

    // -- GENERAL --
    onSave = () => {
        const { comment, dates, assignTo } = this.state;
        const { saveTimeOffItem, currentTimeOff, user, navigation, cleanUp } = this.props;

        if (!this.isDataValid()) {
            return;
        }

        const timeOff = cloneDeep(currentTimeOff);

        const days = 0;
        const timeOffDays = [];
        let totalVacationHours = 0;
        let totalPersonalHours = 0;
        let totalSickHours = 0;
        for (let i = 0; i < dates.length; i++) {
            const d = dates[i];
            const timeOffDay = {
                Date: d.Date.toISOString(),
                PtoBucket: d.PtoBucket.id,
                Hours: d.Hours,
            };
            if (i === 0) {
                timeOff.StartTime = d.Date.toISOString();
            }

            if (i === dates.length - 1) {
                timeOff.EndTime = d.Date.toISOString();
            }
            switch (d.PtoBucket.internalName) {
                case LeaveTypes.Personal:
                    totalPersonalHours += d.Hours;
                    break;
                case LeaveTypes.Vacation:
                    totalVacationHours += d.Hours;
                    break;
                case LeaveTypes.Sick:
                    totalSickHours += d.Hours;
                    break;
            }
            timeOffDays.push(timeOffDay);
        }
        timeOff.DurationType = days > 1 ? 'R' : 'O';
        timeOff.TimeOffDays = timeOffDays;
        timeOff.TotalVacationHours = totalVacationHours;
        timeOff.TotalPersonalHours = totalPersonalHours;
        timeOff.TotalSickHours = totalSickHours;
        timeOff.UserComment = comment;
        timeOff.AdminApprover = [{ User: assignTo.id }];
        if (this.isChanged(timeOff)) {
            timeOff.TicketStatus = 'U';
            saveTimeOffItem({
                timeOff,
                user,
            }).then((success) => {
                if (success) {
                    navigation.navigate('PaidTimeOffList');
                    cleanUp();
                }
            });
        } else {
            navigation.navigate('PaidTimeOffList');
            cleanUp();
        }
    };

    close() {
        this.setState({ confirmationDialog: false, confirmationText: '' });
    }

    render() {
        const { isLoading, assignUsers } = this.props;
        const { assignTo, comment, confirmationDialog, confirmationText } = this.state;
        const backConfirmText =
            // eslint-disable-next-line max-len
            'You are about to back out of your time off booking. All not saved data will be lost. Are you sure you want to do this ?';
        const backTitle = 'Warning';
        return (
            <View style={[s.wrapper]}>
                <NavigationBar
                    {...this.props}
                    title="BOOK TIME OFF"
                    backIcon
                    backConfirm={{
                        title: backTitle,
                        text: backConfirmText,
                    }}
                />
                <View style={[s.mainContainer]}>
                    {this._renderRangePeriod()}

                    <FieldInput
                        title="COMMENT"
                        multiline
                        input={{
                            onChange: (text) => {
                                this.setState({ comment: text });
                            },
                        }}
                        meta={{
                            error: null,
                            touched: false,
                        }}
                        initialValues={comment}
                    />
                    {assignUsers.length > 0 ? (
                        <DropdownList
                            items={assignUsers}
                            dropStyle={s.materialDropdown}
                            selectedItem={assignTo}
                            onItemSelect={(item) => this.setState({ assignTo: item })}
                            title="ASSIGN TO"
                            withIds
                        />
                    ) : (
                        <View />
                    )}

                    <View style={s.buttonWrapper}>
                        <PrimaryButton title="SAVE" onPress={this.onSave} />
                    </View>
                </View>
                <Modal visible={isLoading} transparent>
                    <View style={s.loadingModalWrapper}>
                        <ActivityIndicator color={Color.white} size={40} />
                    </View>
                </Modal>
                <ConfirmationDialog isOpen={confirmationDialog} onPress={() => this.close()} text={confirmationText} />
            </View>
        );
    }
}

const mapStateToProps = ({ timeCards, timeOff, account }) => ({
    isLoading: timeOff.isLoading,
    currentTimeOff: timeOff.currentTimeOffItem,
    timeOffItems: timeOff.timeOffItems,
    ptoBuckets: timeOff.ptoBuckets,
    payPeriods: timeCards.payPeriods,
    assignUsers: timeOff.assignUsers,
    user: account.user,
    settings: account.settings,
});

export default connect(mapStateToProps, {
    saveTimeOffItem,
    cleanUp,
    getPtoSettings,
})(PaidTimeOffItemScreen);
