import { Color, Font } from '../../../theme';

import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: Color.pale_grey_two,
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        paddingBottom: 135,
    },
    mainContainerEntries: {
        paddingHorizontal: '5%',
        paddingBottom: '5%',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '10%',
        paddingBottom: '10%',
    },
    logoImg: {
        width: 150,
        height: 50,
    },
    form: {
        height: '70%',
    },
    listTitle: {
        fontSize: Font.size.font12,
        fontFamily: Font.type.bold,
        color: Color.blue,
        width: '64%',
        marginBottom: 5,
        marginTop: 20,
    },
    listTitleRight: {
        marginRight: 15,
        width: 'auto',
    },
    listTitleWrapper: {
        backgroundColor: Color.pale_grey_two,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    weekMenu: {
        width: '100%',
        paddingHorizontal: '5%',
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    weekMenuIcon: {
        color: Color.blue,
        fontSize: Font.size.font23,
    },
    title: {
        color: Color.blue,
        fontFamily: Font.type.bold,
        fontSize: Font.size.font12,
        marginTop: 3,
    },
    formBlock: {
        backgroundColor: Color.white,
        borderRadius: 10,
        elevation: 12,
        shadowColor: Color.blue,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        width: '90%',
        alignSelf: 'center',
        paddingHorizontal: 20,
    },
    formBlockTitle: {
        color: Color.blue,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font14,
    },
    weekTotal: {
        fontSize: Font.size.font11,
        color: Color.blue,
        fontFamily: Font.type.bold,
        textAlign: 'center',
    },
    weekTotalReg: {
        fontSize: Font.size.font11,
        color: Color.blue,
        fontFamily: Font.type.bold,
        textAlign: 'center',
    },
    formBlockDates: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: 3,
        position: 'relative',
        paddingTop: 3,
    },
    weekDay: {
        flexDirection: 'row',
        width: '100%',
        height: 32,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    weekDayDate: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font12,
        marginRight: 10,
    },
    weekDayName: {
        fontFamily: Font.type.regular,
        color: Color.blue,
        fontSize: Font.size.font12,
    },
    weekDayTimePto: {
        fontFamily: Font.type.regular,
        color: Color.blue,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    weekDayTime_inactivePto: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    weekDayTimeReg: {
        fontFamily: Font.type.regular,
        color: Color.blue,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    weekDayTime_inactiveReg: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    weekDayTimeRegPadding: {
        fontFamily: Font.type.regular,
        color: Color.blue,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    weekDayTime_inactiveRegPadding: {
        fontFamily: Font.type.regular,
        color: Color.light_grey_blue,
        fontSize: Font.size.font11,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    formBlockTotal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 30,
        alignItems: 'center',
    },
    formHeaderBlock: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 47.5,
    },
    formHeaderBlock_paymentPeriod: {
        height: 50,
    },
    formHeaderBlockCol: {
        display: 'block',
    },
    periodStatus: {
        fontFamily: Font.type.bold,
        fontSize: Font.size.font14,
        color: Color.grey,
        marginTop: 5,
    },
    periodStatus_waiting: {
        color: Color.faded_orange,
    },
    periodStatus_approved: {
        color: Color.turquoise,
    },

    buttonWrapper: {
        marginVertical: 20,
        marginHorizontal: 'auto',
        width: 200,
    },
    timeCardEdits: {
        color: '#b2becc',
        fontSize: Font.size.font11,
        textAlign: 'center',
        paddingBottom: 10,
    },
    heading: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 18,
    },
    totalTime: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    reg: {
        fontSize: Font.size.font10,
        color: Color.grey,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    pto: {
        fontSize: Font.size.font10,
        color: Color.grey,
        alignItems: 'flex-end',
        width: 50,
        textAlign: 'right',
    },
    optionButtonsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
    },
    optionButton: {
        backgroundColor: '#3498db',
        padding: 10,
        borderRadius: 5,
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectedButton: {
        backgroundColor: '#2c3e50',
    },
    optionButtonText: {
        color: 'white',
        fontSize: 16,
    },
});
