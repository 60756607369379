import Constants from 'expo-constants';

export default {
    ENVIRONMENT: Constants.expoConfig.extra.ENVIRONMENT,
    DXP_TOKEN: '',
    DXP_ROOT_URL: Constants.expoConfig.extra.DXP_ROOT_URL,
    DXP_USERS_ROOT_URL: Constants.expoConfig.extra.DXP_USERS_ROOT_URL,
    DXP_FILE_UPLOAD: Constants.expoConfig.extra.DXP_FILE_UPLOAD,
    CONSUMER_WEB_HOST: Constants.expoConfig.extra.CONSUMER_WEB_HOST,
    WEATHER_API_KEY: 'f3753ed127182cf357eb5f42d63a34d4',
    ALLOW_FORGOT_CLOCK_IN_PERM: 'allow-forgot-clock-in',
    // source ids, will be set later after user login
    PROJECTS: '',
    TIMECARDS: '',
    TIMECARD_MODIFICATIONS: '',
    COST_CODES: '',
    REPORTS: '',
    TAM: '',
    ORDER_CHANGES: '',
    TIME_OFF: '',
    DAILY_QUESTIONS: '',
    DAILY_QUESTIONS_RESPONSES: '',
    INJURY_REPORTING_TYPES: '',
    INJURY_REPORTING_TICKETS: '',
    ACCIDENT_REPORTING: '',
    TOOLBOX_TALKS: '',
    TOOLBOX_TYPES: '',
    TOOLBOX_USER_SIGN: '',
    DEPARTMENTS: '',
    EMPLOYEE_HANDBOOK: '',
    PAY_PERIODS: '',
    CUSTOMER: '',
    CUSTOM_REPORTS: '',
    CUSTOM_REPORT_ANSWERS: '',
    CUT_SHEETS: '',
    NOTIFICATIONS: '',
    SHIFTS: '',
    SHIFT_TIME_OFF_REQUESTS: '',
    REIMBURSEMENT_ITEMS: '',
    REIMBURSEMENT_REQUESTS: '',
    HOLIDAYS: '',
    PAYROLL_CHECK_DATES: '',
    PTO_BUCKETS: '',
    SIGNED_TIMECARDS: '',
    USE_DEPARTMENTS_JOBS: false,
};
