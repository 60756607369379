import AsyncStorage from '@react-native-async-storage/async-storage';
import { cloneDeep } from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import { showMessage } from 'react-native-flash-message';
import CNST from '../constants';
import { Config } from '../../config';
import Api from '../../utils/apiMiddleware';
import { Color } from '../../theme';
import { checkIpLock } from '../../utils/helpers';
import { getPayPeriods } from './timeCards';

function setSourceIds(sources) {
    if (sources.Projects || sources.Departments) {
        Config.PROJECTS = sources.Projects || sources.Departments;
    }

    if (sources.Timecards) {
        Config.TIMECARDS = sources.Timecards;
    }

    if (sources.Holidays) {
        Config.HOLIDAYS = sources.Holidays;
    }

    if (sources.TimeCardModification) {
        Config.TIMECARD_MODIFICATIONS = sources.TimeCardModification;
    }

    if (sources.CostCodes || sources.Jobs) {
        Config.COST_CODES = sources.CostCodes || sources.Jobs;
    }

    if (sources.DailyReports) {
        Config.REPORTS = sources.DailyReports;
    }

    if (sources.TimeAndMaterials) {
        Config.TAM = sources.TimeAndMaterials;
    }

    if (sources.ChangeOrder) {
        Config.ORDER_CHANGES = sources.ChangeOrder;
    }

    if (sources.TimeOff) {
        Config.TIME_OFF = sources.TimeOff;
    }

    if (sources.DailyQuestionsCategories) {
        Config.DAILY_QUESTIONS = sources.DailyQuestionsCategories;
    }

    if (sources.DailyQuestionsUserResponses) {
        Config.DAILY_QUESTIONS_RESPONSES = sources.DailyQuestionsUserResponses;
    }

    if (sources.InjuryCategories) {
        Config.INJURY_REPORTING_TYPES = sources.InjuryCategories;
    }

    if (sources.InjuryTickets) {
        Config.INJURY_REPORTING_TICKETS = sources.InjuryTickets;
    }

    if (sources.AccidentReporting) {
        Config.ACCIDENT_REPORTING = sources.AccidentReporting;
    }

    if (sources.GivenToolBoxTalks) {
        Config.TOOLBOX_TALKS = sources.GivenToolBoxTalks;
    }

    if (sources.ToolBoxTalks) {
        Config.TOOLBOX_TYPES = sources.ToolBoxTalks;
    }

    if (sources.UserSignOffToolBoxTalks) {
        Config.TOOLBOX_USER_SIGN = sources.UserSignOffToolBoxTalks;
    }

    if (sources.CompanyDepartments) {
        Config.DEPARTMENTS = sources.Departments;
    }

    if (sources.EmployeeHandbook) {
        Config.EMPLOYEE_HANDBOOK = sources.EmployeeHandbook;
    }

    if (sources.PayPeriods) {
        Config.PAY_PERIODS = sources.PayPeriods;
    }

    if (sources.CutSheets) {
        Config.CUT_SHEETS = sources.CutSheets;
    }

    if (sources.Notifications) {
        Config.NOTIFICATIONS = sources.Notifications;
    }

    if (sources.Departments && sources.Jobs) {
        Config.USE_DEPARTMENTS_JOBS = true;
    }

    if (sources.Shifts) {
        Config.SHIFTS = sources.Shifts;
    }

    if (sources.ShiftTimeOffRequests) {
        Config.SHIFT_TIME_OFF_REQUESTS = sources.ShiftTimeOffRequests;
    }

    if (sources.PayrollCheckDates) {
        Config.PAYROLL_CHECK_DATES = sources.PayrollCheckDates;
    }

    if (sources.ReimbursementItems) {
        Config.REIMBURSEMENT_ITEMS = sources.ReimbursementItems;
    }

    if (sources.ReimbursementRequests) {
        Config.REIMBURSEMENT_REQUESTS = sources.ReimbursementRequests;
    }

    if (sources.PtoBuckets) {
        Config.PTO_BUCKETS = sources.PtoBuckets;
    }

    if (sources.SignedTimeCards) {
        Config.SIGNED_TIMECARDS = sources.SignedTimeCards;
    }
}

// ------------------------------------
// Actions
// ------------------------------------

export function getPayrollCheckDates(payFrequency) {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.PAYROLL_CHECK_DATES}/data`, {
                limit: 9999,
                sort: 'StartDate',
            })
            .then((response) => {
                const data = response.data.filter((d) => d.StatusCompleted === 'NO' && d.Frequency === payFrequency);
                dispatch({
                    type: CNST.ACCOUNT.GET_PAYROLL_CHECK_DATES,
                    data,
                });
                return true;
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

export function userLogin(data = {}) {
    const { email, password, signInCode } = data;
    const body = {
        username: email,
        password: password || 'pass',
        signInCode,
        settingFields: ['logoUrl', 'sources', 'pto', 'generalRules', 'company'],
        isAutoLogin: !password,
    };
    return async (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.LOGIN.LOADING,
        });

        try {
            const response = await Api().post('/login', body);
            const { user, userPermissions, setting: settings, token } = response;

            if (!Object.prototype.hasOwnProperty.call(userPermissions, 'web-app-access')) {
                showMessage({
                    message: 'Denied Access',
                    description: 'Your account does not have web access allowed',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
                dispatch({
                    type: CNST.ACCOUNT.LOGIN.FAILED,
                });
                return false;
            }

            // set source ids
            if (settings && settings.sources) {
                setSourceIds(settings.sources);
            }

            const isIpCorrect = await checkIpLock(settings);
            if (!isIpCorrect) {
                dispatch({
                    type: CNST.ACCOUNT.LOGIN.FAILED,
                });
                return false;
            }

            // set api key
            if (token) {
                user.token = token;
                Config.DXP_TOKEN = token;
                dispatch(getPayPeriods());
            }

            dispatch({
                type: CNST.ACCOUNT.LOGIN.SUCCESS,
                user,
                userPermissions,
                settings,
            });

            return true;
        } catch (error) {
            console.log('login error', error);
            let errorMessage = 'Connection Failure';
            let errorDescription = 'We are unable to connect. Please make sure that you have a data connection.';
            const dispatchData = {
                type: CNST.ACCOUNT.LOGIN.FAILED,
            };

            if (error && error.statusCode) {
                errorMessage = 'Login Error';
                errorDescription = error.message;
                if (errorDescription.includes('incorrect')) {
                    errorDescription += '. Please try again or click the “Forgot Password” link.';
                }
            }
            showMessage({
                message: errorMessage,
                description: errorDescription,
                type: 'danger',
                duration: 5000,
                animationDuration: 700,
                hideOnPress: true,
                hideStatusBar: true,
                backgroundColor: Color.red,
            });
            dispatch(dispatchData);
            return false;
        }
    };
}

export function userAutoLogin(user, autoToken) {
    const body = {
        token: autoToken,
        settingFields: ['logoUrl', 'sources', 'pto'],
    };
    return async (dispatch) => {
        // check if user already logged in with same token
        if (user && user.autoToken && user.autoToken === autoToken) {
            return null;
        }

        dispatch({
            type: CNST.ACCOUNT.LOGIN.LOADING,
            auto: true,
        });

        try {
            const response = await Api().post('/jwt-login', body);
            const { user, userPermissions, setting: settings, token } = response;

            // set api key
            if (token) {
                user.token = token;
                user.autoToken = autoToken;
                Config.DXP_TOKEN = token;
            }

            // set source ids
            if (settings && settings.sources) {
                setSourceIds(settings.sources);
                await dispatch(getPayPeriods());
            }

            dispatch({
                type: CNST.ACCOUNT.LOGIN.SUCCESS,
                user,
                userPermissions,
                settings,
            });
            return user;
        } catch (error) {
            console.log('login error', error);
            if (error && error.statusCode) {
                let errorMessage = error.message;
                if (errorMessage.includes('incorrect')) {
                    errorMessage += '. Please try again or click the “Forgot Password” link.';
                }
                showMessage({
                    message: 'Login error',
                    description: errorMessage,
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                });
                dispatch({
                    type: CNST.ACCOUNT.LOGIN.FAILED,
                });
                return false;
            }
            showMessage({
                message: 'Connection failure!',
                description: 'We are unable to connect. Please make sure that you have a data connection.',
                type: 'danger',
                duration: 5000,
                animationDuration: 700,
                // autoHide: false,
                hideOnPress: true,
                hideStatusBar: true,
            });
            dispatch({
                type: CNST.ACCOUNT.LOGIN.FAILED,
                // response: error,
            });
            return false;
        }
    };
}

export function userUpdate(id) {
    return (dispatch) => {
        return Api()
            .get(`/users/${id}`, {
                returnPermissions: false,
                returnSchema: false,
                returnUserPermissions: true,
                returnSourceIds: true,
            })
            .then((user) => {
                if (user && user.sourceIds) {
                    setSourceIds(user.sourceIds);
                }

                return dispatch({
                    type: CNST.ACCOUNT.USER_UPDATE,
                    user,
                });
            })
            .catch((error) => {
                if (error.statusCode && error.statusCode === 401) {
                    // we are not authorized
                    return dispatch(userLogOut());
                }

                showMessage({
                    message: 'Connection Failure',
                    description: 'We are unable to connect. Please make sure that you have a data connection.',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
                return false;
            });
    };
}

export function userForgotPassword(data = {}) {
    const { email } = data;
    const body = {
        email,
        resetUrl: `https://${Config.CONSUMER_WEB_HOST}/login/reset`,
    };
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.FORGOT_PASSWORD.LOADING,
        });

        return Api()
            .post('/login/forgot', body)
            .then((response) => {
                dispatch({
                    type: CNST.ACCOUNT.FORGOT_PASSWORD.SUCCESS,
                    response,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CNST.ACCOUNT.FORGOT_PASSWORD.FAILED,
                    // response: 'The email or password you entered is incorrect',
                });
                return error;
            });
    };
}

export function resetPassword(data = {}) {
    const { resetKey, resetEmail, password, confirmPassword } = data;
    const body = {
        key: resetKey,
        email: resetEmail,
        password,
        passwordRepeat: confirmPassword,
    };
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.RESET_PASSWORD.LOADING,
        });

        return Api()
            .post('/login/reset', body)
            .then((response) => {
                dispatch({
                    type: CNST.ACCOUNT.RESET_PASSWORD.SUCCESS,
                    response,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CNST.ACCOUNT.RESET_PASSWORD.FAILED,
                    // response: 'The email or password you entered is incorrect',
                });
                return error;
            });
    };
}

export function checkIsUserActive(data = {}) {
    const { email } = data;
    return () => {
        return Api()
            .get('/users', { limit: 10, search: email })
            .then((response) => {
                const { data } = response;
                if (data.length === 0) {
                    return true;
                }
                return data[0].isActive;
            })
            .catch(() => {
                return false;
            });
    };
}

export function userLogOut() {
    return (dispatch) => {
        return new Promise((resolve) => {
            AsyncStorage.getItem('token').then(() => {
                dispatch({
                    type: CNST.ACCOUNT.LOGOUT.SUCCESS,
                });
            });
            AsyncStorage.multiRemove(['user']).then(resolve);
        });
    };
}

export function loadProjects(page, search) {
    return (dispatch) => {
        const payload = {
            fetchRelatedData: false,
            limit: 100,
            page: page + 1,
            previewData: false,
            relatedSearch: !!search,
            search,
            sort: 'ProjectName',
        };
        return Api()
            .post(`/sources/${Config.PROJECTS}/data`, payload)
            .then((response) => {
                const { data, pages } = response;
                dispatch({
                    type: CNST.ACCOUNT.GET_PROJECTS,
                    data,
                    page: page + 1,
                    totalPages: pages ? pages.total : 0,
                });
                return data;
            })
            .catch((error) => {
                showMessage({
                    message:
                        // eslint-disable-next-line max-len
                        'No data connection detected. If your project is not available, please sign in to Unassigned and contact Payroll.',
                    type: 'default',
                    backgroundColor: Color.faded_orange,
                    color: Color.dark_navy_blue,
                    duration: 5000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                });
                return error;
            });
    };
}

export function getProject(id) {
    return Api()
        .get(`/sources/${Config.PROJECTS}/data`, { searchField: 'id', search: id })
        .then((response) => {
            const { data } = response;
            return data;
        });
}

export function getProjectByExternalId(id) {
    return Api()
        .get(`/sources/${Config.PROJECTS}/data`, { searchField: 'externalId', search: id })
        .then((response) => {
            const { data } = response;
            return data && data.length > 0 ? data[0] : null;
        });
}

export function getNotifications() {
    return (dispatch) => {
        if (!Config.NOTIFICATIONS) {
            return false;
        }

        const currentDate = new Date().toISOString();
        const requestData = {
            limit: 9999,
            page: 1,
            sort: 'timeCreated',
            fetchCustomData: false,
            fetchRelatedData: false,
            relatedSearch: false,
            searchCriteria: {
                IsActive: 'true',
                StartDate: [
                    {
                        op: 'lessOrEqual',
                        value: currentDate,
                    },
                ],
                EndDate: [
                    {
                        op: 'greaterOrEqual',
                        value: currentDate,
                    },
                ],
                Targets: 'web',
            },
        };

        return Api()
            .post(`/sources/${Config.NOTIFICATIONS}/data`, requestData)
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: CNST.ACCOUNT.GET_NOTIFICATIONS,
                    data,
                });
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

export function getCostCode(id) {
    return () => {
        return Api()
            .get(`/sources/${Config.COST_CODES}/data`, {
                searchField: '_id',
                search: id,
                limit: 200,
            })
            .then((response) => {
                const { data } = response;

                let project;
                data.forEach((d) => {
                    if (d.id === id) {
                        project = d;
                    }
                });
                return project;
            });
    };
}

export function getCostCodes() {
    return (dispatch) => {
        return Api()
            .get(`/sources/${Config.COST_CODES}/data`, { limit: 500 })
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: CNST.ACCOUNT.GET_COST_CODES,
                    data,
                });
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

export function getDepartment(id) {
    return (dispatch) => {
        return Api()
            .post(`/sources/${Config.DEPARTMENTS}/data`, {
                limit: 1,
                searchCriteria: {
                    _id: id,
                },
            })
            .then((response) => {
                const { data } = response;
                let department = null;
                if (data.length > 0) {
                    [department] = data;
                }
                dispatch({
                    type: CNST.ACCOUNT.GET_DEPARTMENT,
                    department,
                });
            });
    };
}

export function getUserPermissions(userID) {
    return (dispatch) => {
        return Api()
            .get(`/users/${userID}`)
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: CNST.ACCOUNT.USER_PERMISSIONS,
                    data,
                });
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

export function getDailyQuestions() {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.DAILY_QUESTIONS_FETCH.LOADING,
        });
        return Api()
            .get(`/sources/${Config.DAILY_QUESTIONS}/data`, { limit: 1000 })
            .then((response) => {
                dispatch({
                    type: CNST.ACCOUNT.DAILY_QUESTIONS_FETCH.SUCCESS,
                    data: response.data,
                });
                return true;
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

export function getUsers(status = 'all') {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.GET_USERS.LOADING,
        });
        return Api()
            .get(`/users`, { filter: status, limit: 500 })
            .then((response) => {
                dispatch({
                    type: CNST.ACCOUNT.GET_USERS.SUCCESS,
                    data: response.data,
                });
                return true;
            })
            .catch((error) => {
                console.log('error', error);
                return error;
            });
    };
}

export function saveProfilePhoto(user, image, callback) {
    const meta = cloneDeep(user.meta);
    meta.UserPhoto = image.file.url;
    meta.UserPhotoAssetId = image._id;
    return (dispatch) => {
        return Api()
            .put(`/users/${user._id}/meta`, { meta })
            .then((response) => {
                dispatch({
                    type: CNST.ACCOUNT.USER_PHOTO_UPLOAD.SUCCESS,
                    cleanPhotoCache: true,
                });
                if (response) {
                    dispatch({
                        type: CNST.ACCOUNT.USER_UPDATE_PROFILE_PHOTO,
                        user: response,
                    });
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: CNST.ACCOUNT.USER_PHOTO_UPLOAD.ERROR,
                });
                console.log(error);
                showMessage({
                    message: 'Save Photo Error',
                    description:
                        // eslint-disable-next-line max-len
                        'An error occurred while saving photo. Your photo has been saved on your device and will be sent next time.',
                    type: 'danger',
                    duration: 5000,
                    animationDuration: 700,
                    hideOnPress: true,
                    hideStatusBar: true,
                    backgroundColor: Color.red,
                });
                return false;
            });
    };
}

export function profilePhotoUploading() {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.USER_PHOTO_UPLOAD.LOADING,
        });
    };
}

export function profilePhotoUploadingFinish() {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.USER_PHOTO_UPLOAD.SUCCESS,
            cleanPhotoCache: false,
        });
    };
}

export function cacheProfilePhoto(photo) {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.USER_PHOTO_UPLOAD.CACHE,
            photo,
        });
    };
}

export function cacheRecentProjects(projects) {
    return (dispatch) => {
        dispatch({
            type: CNST.ACCOUNT.CACHE_RECENT_PROJECTS,
            projects,
        });
    };
}

export function initApp() {
    return (dispatch) => {
        dispatch({
            type: CNST.INIT_APP,
        });
    };
}

export function getEmployeeHandBooks() {
    return (dispatch) => {
        return NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                return Api()
                    .get(`/sources/${Config.EMPLOYEE_HANDBOOK}/data`, { limit: 1000 })
                    .then((response) => {
                        const { data } = response;
                        dispatch({
                            type: CNST.ACCOUNT.GET_EMPLOYEE_HANDBOOKS,
                            data,
                        });
                        return true;
                    });
            }
            showMessage({
                message: 'No Data Connection Detected',
                type: 'danger',
                duration: 5000,
                animationDuration: 700,
                hideOnPress: true,
                hideStatusBar: true,
                backgroundColor: Color.red,
            });
            return false;
        });
    };
}

export function getPtoSettings() {
    return (dispatch) => {
        return NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                return Api()
                    .get('/settings/pto')
                    .then((response) => {
                        const { pto } = response;
                        dispatch({
                            type: CNST.ACCOUNT.GET_PTO_SETTINGS.SUCCESS,
                            pto,
                        });
                        return true;
                    });
            }
            return false;
        });
    };
}

export function getGeneralRulesSettings() {
    return (dispatch) => {
        return NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                return Api()
                    .get('/settings/generalRules')
                    .then((response) => {
                        const { generalRules } = response;
                        dispatch({
                            type: CNST.ACCOUNT.GET_GENERAL_RULES_SETTINGS.SUCCESS,
                            generalRules,
                        });
                        return true;
                    });
            }
            return false;
        });
    };
}

export function getTimeCardRules() {
    return (dispatch) => {
        return NetInfo.fetch().then((state) => {
            const { isConnected } = state;
            if (isConnected) {
                return Api()
                    .get('/settings/timeCardRules')
                    .then((response) => {
                        const { timeCardRules } = response;
                        dispatch({
                            type: CNST.ACCOUNT.GET_TIME_CARD_RULES_SETTINGS.SUCCESS,
                            timeCardRules,
                        });
                        return true;
                    });
            }
            return false;
        });
    };
}

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
    user: null,
    settings: {},
    error: '',
    isLoading: false,
    isAutoLoginLoading: false,
    isPhotoUploading: false,
    isMenuOpen: false,
    projects: [],
    projectsCurrentPage: 0,
    recentProjects: [],
    invalidateRecentProjects: false,
    costCodes: [],
    userCanCreateChangeOrders: false,
    userCanCreateTimeAndMaterial: false,
    dailyQuestions: [],
    users: [],
    department: null,
    cachedPhoto: null,
    employeeHandBooks: [],
    notifications: [],
    payRollCheckDates: [],
};

export default function accountReducer(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case CNST.ACCOUNT.GET_PTO_SETTINGS.SUCCESS: {
            const updatedSettings = {
                ...state.settings,
                pto: action.pto,
            };
            return {
                ...state,
                settings: updatedSettings,
            };
        }
        case CNST.ACCOUNT.GET_GENERAL_RULES_SETTINGS.SUCCESS: {
            const updatedSettings = {
                ...state.settings,
                generalRules: action.generalRules,
            };
            return {
                ...state,
                settings: updatedSettings,
            };
        }
        case CNST.ACCOUNT.GET_TIME_CARD_RULES_SETTINGS.SUCCESS: {
            const updatedSettings = {
                ...state.settings,
                timeCardRules: action.timeCardRules,
            };
            return {
                ...state,
                settings: updatedSettings,
            };
        }
        case CNST.ACCOUNT.GET_PAYROLL_CHECK_DATES: {
            return {
                ...state,
                payRollCheckDates: action.data,
            };
        }
        case CNST.ACCOUNT.GET_EMPLOYEE_HANDBOOKS:
            return {
                ...state,
                employeeHandBooks: action.data,
            };
        case CNST.ACCOUNT.GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.data,
            };
        case CNST.ACCOUNT.LOGIN.SUCCESS: {
            const { user, userPermissions, settings } = action;
            if (!user.fullName) {
                user.fullName = user.name ? `${user.name.first} ${user.name.last}`.trim() : '';
            }
            user.permissions = userPermissions;
            return {
                ...state,
                error: '',
                user,
                settings,
                isLoading: false,
                isAutoLoginLoading: false,
            };
        }
        case CNST.ACCOUNT.USER_UPDATE: {
            const { user } = action;
            if (!user.fullName) {
                user.fullName = user.name ? `${user.name.first} ${user.name.last}`.trim() : '';
            }
            const currentUser = state.user;
            return {
                ...state,
                user: {
                    ...currentUser,
                    ...user,
                },
            };
        }
        case CNST.ACCOUNT.USER_UPDATE_PROFILE_PHOTO: {
            const { user } = action;

            const currentUser = state.user;
            return {
                ...state,
                user: {
                    ...currentUser,
                    meta: {
                        ...currentUser.meta,
                        UserPhoto: user.meta.UserPhoto,
                    },
                },
            };
        }
        case CNST.ACCOUNT.USER_PERMISSIONS: {
            return {
                ...state,
                ...{
                    userCanCreateChangeOrders: action.data[0].meta.AllowedToCreateCO,
                    userCanCreateTimeAndMaterial: action.data[0].meta.AllowedToCreatedCPA,
                },
            };
        }
        case CNST.ACCOUNT.FORGOT_PASSWORD.SUCCESS:
            return { ...state, ...{ isLoading: false } };
        case CNST.ACCOUNT.LOGIN.FAILED:
            return { ...state, ...{ isLoading: false, isAutoLoginLoading: false } };
        case CNST.ACCOUNT.FORGOT_PASSWORD.FAILED:
            return {
                ...state,
                ...{
                    error: action.response,
                    isLoading: false,
                    user: null,
                },
            };
        case CNST.ACCOUNT.LOGIN.LOADING: {
            const newState = { ...state, ...{ isLoading: true } };
            if (action.auto) {
                newState.isAutoLoginLoading = true;
            }

            return newState;
        }
        case CNST.ACCOUNT.FORGOT_PASSWORD.LOADING:
        case CNST.ACCOUNT.GET_USERS.LOADING:
            return { ...state, ...{ isLoading: true } };
        case CNST.ACCOUNT.RESET_PASSWORD.LOADING:
            return { ...state, ...{ isLoading: true } };
        case CNST.ACCOUNT.RESET_PASSWORD.SUCCESS:
            return { ...state, ...{ isLoading: false } };
        case CNST.ACCOUNT.RESET_PASSWORD.FAILED:
            return { ...state, ...{ isLoading: false } };
        case CNST.ACCOUNT.LOGOUT.SUCCESS:
            return { ...state, ...cloneDeep(initialState) };
        case CNST.ACCOUNT.TOGGLE_MENU:
            return { ...state, ...{ isMenuOpen: !state.isMenuOpen } };
        case CNST.ACCOUNT.GET_PROJECTS:
            return {
                ...state,
                ...{
                    projects: action.page === 1 ? [...action.data] : [...state.projects, ...action.data],
                    projectsCurrentPage: action.page,
                    projectsTotalPages: action.totalPages,
                },
            };
        case CNST.ACCOUNT.GET_COST_CODES:
            return { ...state, ...{ costCodes: action.data } };
        case CNST.ACCOUNT.GET_DEPARTMENT:
            return {
                ...state,
                ...{
                    department: action.department,
                },
            };
        case CNST.ACCOUNT.DAILY_QUESTIONS_FETCH.SUCCESS:
            return { ...state, ...{ dailyQuestions: action.data } };
        case CNST.ACCOUNT.GET_USERS.SUCCESS:
            return { ...state, ...{ isLoading: false, users: action.data } };
        case CNST.ACCOUNT.USER_PHOTO_UPLOAD.LOADING:
            return { ...state, ...{ isPhotoUploading: true } };
        case CNST.ACCOUNT.USER_PHOTO_UPLOAD.ERROR:
            return { ...state, ...{ isPhotoUploading: false } };
        case CNST.ACCOUNT.USER_PHOTO_UPLOAD.SUCCESS: {
            const updatedState = {
                isPhotoUploading: false,
            };
            if (action.cleanPhotoCache) {
                updatedState.cachedPhoto = null;
            }
            return { ...state, ...updatedState };
        }
        case CNST.ACCOUNT.USER_PHOTO_UPLOAD.CACHE:
            return {
                ...state,
                cachedPhoto: action.photo,
            };
        case CNST.ACCOUNT.CACHE_RECENT_PROJECTS:
            return {
                ...state,
                recentProjects: action.projects,
                invalidateRecentProjects: false,
            };
        case CNST.INIT_APP:
            return {
                ...state,
                invalidateRecentProjects: true,
                projectsCurrentPage: 0,
            };
        case 'persist/REHYDRATE': {
            if (action.payload) {
                const { account } = action.payload;
                if (account.settings && account.settings.sources) {
                    // restore source ids
                    setSourceIds(account.settings.sources);
                }

                if (account.user && account.user.token) {
                    Config.DXP_TOKEN = account.user.token;
                }

                return { ...action.payload.account };
            }
            return state;
        }
        default:
            return state;
    }
}
