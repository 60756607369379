import { StyleSheet, Platform } from 'react-native';
import { Color, Font } from '../../theme';

const isIos = Platform.OS === 'ios';
export default StyleSheet.create({
    dropdownContainer: {
        backgroundColor: Color.white,
        borderRadius: 6,
        paddingHorizontal: 10,
        height: 50,
        width: '100%',
        elevation: 10,
    },
    dropdownLabel: {
        color: Color.grey,
        fontSize: Font.size.font10,
        fontFamily: Font.type.regular,
    },
    dropdownLabel_item: {
        color: Color.blue,
        fontSize: Font.size.font14,
    },
    dropdownLabelInput: {
        color: Color.blue,
        fontSize: Font.size.font14,
        fontWeight: 'bold',
        border: 'none',
    },
    touchableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: 50,
        alignItems: 'center',
    },
    dropdownText: {
        marginTop: -50,
        borderBottomWidth: 0,
    },
    dropdownWrapper: {
        width: '90%',
        paddingVertical: 9,
        backgroundColor: Color.white,
        borderRadius: 6,
        elevation: 8,
        alignSelf: 'center',
    },
    dropDownItem: {
        width: '100%',
        height: 36,
        backgroundColor: Color.very_light_blue,
        paddingHorizontal: 20,
        justifyContent: 'center',
    },
    dropDownItemText: {
        color: Color.light_navy,
        fontFamily: Font.type.regular,
        fontSize: Font.size.font12,
    },
    modalCloseSpace: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
    topLabel: {
        fontFamily: Font.type.regular,
        color: Color.grey,
        fontSize: Font.size.font10,
        marginBottom: isIos ? 5 : -15,
        height: 15,
        marginTop: isIos ? 5 : 7,
        marginLeft: isIos ? 10 : 0,
        flexDirection: isIos ? 'row' : 'column',
    },
    iosPadding: {
        marginLeft: 10,
        paddingRight: 20,
        width: '100%',
    },
    dateBlockWrapper: {
        position: 'relative',
        width: '100%',
    },
    dateIcon: {
        position: 'absolute',
        right: 10,
        top: 26,
    },
    disabled: {
        backgroundColor: Color.light_grey,
    },
});
